import React from "react";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";

export default function ErrorView() {
    return (
        <div className="error-layout">
            <div className="error-wrapper">
                <p className="title">{Labels.ERROR_PAGE_404}</p>
                <p className="info">{Labels.ERROR_PAGE_PAGE_NOT_FOUND}</p>
            </div>
        </div>
    );
}