import { ColumnFilterMatchModeType, Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import React, { useContext, useRef } from "react";
import { Button } from "primereact/button";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { tooltipOptionsRight, date, time } from "../../infrastructure/Utils";
import ButtonComponent from "../common/datatable/buttons/ButtonComponent";
import DatatableContainer from "../common/datatable/DatatableContainer";
import articleColumnNames from "./articleColumnNames";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import CrudArticleDialogContainer from "./crud-dialog/CrudArticleDialogContainer";
import { ArticleContext } from "./ArticleListContainer";
import ArticleReadDto from "../../model/article/ArticleReadDto";
import useArticleController from "../../controllers/article/useArticleController";
import { Dropdown } from "primereact/dropdown";

export default function ArticleListView() {
  const { getArticlesList } = useArticleController();

  const dataTableRef: React.MutableRefObject<any> = useRef<any>(null);

  const {
    openDialog,
    state: { reload_needed, article_type_list },
    setReloadNeeded,
  } = useContext(ArticleContext);

  const articleColumnProps = {
    filter: true,
    filterMatchMode: "contains" as ColumnFilterMatchModeType,
    showFilterMenu: false,
  };

  const buttonListTemplate = (row: ArticleReadDto) => {
    return (
      <div className="d-flex align-items-end">
        <ButtonComponent tooltip={Labels.ARTICLE_TOOLTIP_UPDATE} icon="pi-pencil" onClick={openDialog(EntityOperation.UPDATE, row)} />
        <ButtonComponent tooltip={Labels.ARTICLE_TOOLTIP_READ} icon="pi-info-circle" onClick={openDialog(EntityOperation.READ, row)} />
        <ButtonComponent tooltip={Labels.ARTICLE_TOOLTIP_DELETE} icon="pi-trash" className="red-button" onClick={openDialog(EntityOperation.DELETE, row)} />
      </div>
    );
  };

  const renderDate = (row: ArticleReadDto) => {
    return (
      <>
        <p className="mb-0">{date(row[articleColumnNames.INSERT_TIMESTAMP])}</p>
        <p className="mb-0">{time(row[articleColumnNames.INSERT_TIMESTAMP])}</p>
      </>
    );
  };

  const renderArticleType = (row: ArticleReadDto) => {
    const articleTypeName = article_type_list.find((type: any) => type?.code === row.articleType);
    return <div>{articleTypeName?.name}</div>;
  };

  const statusRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={article_type_list}
        optionLabel={"name"}
        optionValue={"code"}
        onChange={(e) => {
          options.filterApplyCallback(e.value);
        }}
        className="p-column-filter"
        showClear
      />
    );
  };

  return (
    <div className="datatable-layout m-8 mt-5 px-5">
      <Button
        className="create-article-button rounded-button"
        icon="pi pi-plus"
        onClick={openDialog(EntityOperation.CREATE)}
        tooltip={Labels.ARTICLE_TOOLTIP_CREATE}
        tooltipOptions={tooltipOptionsRight}
      />

      <div className="datatable-responsive">
        <DatatableContainer reload_needed={reload_needed} setReloadNeeded={setReloadNeeded} dataFetchCallback={getArticlesList} dataTableRef={dataTableRef}>
          <Column field={articleColumnNames.ARTICLE_CATEGORY} header={Labels.ARTICLE_CATEGORY} {...articleColumnProps} sortable />
          <Column
            field={articleColumnNames.ARTICLE_TYPE}
            body={renderArticleType}
            header={Labels.ARTICLE_TYPE}
            className="w-2"
            filter
            filterElement={statusRowFilterTemplate}
            showFilterMenu={false}
            sortable
          />
          <Column field={articleColumnNames.TITLE} header={Labels.ARTICLE_TITLE} {...articleColumnProps} sortable />
          <Column field={articleColumnNames.DISPLAY_ORDER} header={Labels.ARTICLE_DISPLAY_ORDER} {...articleColumnProps} sortable />
          <Column field={articleColumnNames.INSERT_TIMESTAMP} header={Labels.ARTICLE_INSERT_TIMESTAMP} {...articleColumnProps} body={renderDate} className="w-2" filter={false} sortable />
          <Column body={buttonListTemplate} />
        </DatatableContainer>
      </div>

      <CrudArticleDialogContainer />
    </div>
  );
}
