enum objectFieldNames {
  NAME = "name",
  TITLE = "title",
  DISPLAY_ORDER = "displayOrder",
  IS_MEDICAL_ARTICLE = "isMedicalArticle",
  ROLE_LIST = "articleCategoryRole",
  ARTICLE_TYPE = "articleType",
  ARTICLE_CATEGORY = "articleCategory",
  SUMMARY_IMAGE = "summaryImage",
  HEADER_IMAGE = "headerImage",
  BODY = "body",
  URL = "url",
}

export default objectFieldNames;
