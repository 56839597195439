import { InputNumberValueChangeParams } from "primereact/inputnumber";
import React, { ChangeEvent, useContext, useState } from "react";
import Labels from "../../../../infrastructure/Labels_sr_Latn_RS";
import { ArticleContext } from "../../ArticleListContainer";
import { TabPanel, TabView } from "primereact/tabview";
import { DropdownChangeParams } from "primereact/dropdown";
import ArticleBasicComponent from "./tabs/ArticleBasicComponent";
import ArticleSummaryComponent from "./tabs/ArticleSummaryComponent";
import ArticleShortOverviewComponent from "./tabs/ArticleShortOverviewComponent";
import ArticleContentOverviewComponent from "./tabs/ArticleContentOverviewComponent";
import { EditorTextChangeParams } from "primereact/editor";
import EnumArticleType from "../../../../model/article/EnumArticleType";

interface CrudArticleViewProps {
  onChangeTextInput: (fieldName: string) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | InputNumberValueChangeParams) => void;
  onChangeDropdownValue: (fieldName: string, emptyFieldName?: string) => (event: DropdownChangeParams) => void;
  onDropPicture: (fieldName: string) => (picture: any) => void;
  onChangeEditorInput: (fieldName: string) => (event: EditorTextChangeParams) => void;
  onRemovePicture: (fieldName: string) => void;
}

export default function CrudArticleView({ onChangeTextInput, onChangeDropdownValue, onDropPicture, onChangeEditorInput, onRemovePicture }: CrudArticleViewProps) {
  const [index, setIndex] = useState(0);
  const {
    state: { object },
  } = useContext(ArticleContext);

  let disabledTabs = !object?.articleType;

  return (
    <TabView
      activeIndex={index}
      onTabChange={(e) => {
        setIndex(e.index);
      }}
    >
      <TabPanel header={Labels.TAB_ARTICLE_SUMMARY}>
        <ArticleBasicComponent onChangeTextInput={onChangeTextInput} onChangeDropdownValue={onChangeDropdownValue} onDropPicture={onDropPicture} onRemovePicture={onRemovePicture} />
      </TabPanel>

      <TabPanel header={Labels.TAB_ARTICLE_CONTENT} disabled={disabledTabs}>
        <ArticleSummaryComponent onChangeTextInput={onChangeTextInput} onChangeEditorInput={onChangeEditorInput} />
      </TabPanel>

      <TabPanel header={Labels.TAB_ARTICLE_PREVIEW_SUMMARY} disabled={disabledTabs}>
        <ArticleShortOverviewComponent />
      </TabPanel>
      {(object?.articleType !== EnumArticleType.VIDEO || object?.articleType !== EnumArticleType.HTML) && (
        <TabPanel header={Labels.TAB_ARTICLE_PREVIEW_CONTENT} disabled={disabledTabs}>
          <ArticleContentOverviewComponent />
        </TabPanel>
      )}
    </TabView>
  );
}
