enum actions {
  OPEN_DIALOG = "open_dialog",
  CLOSE_DIALOG = "close_dialog",
  OPEN_DELETE_CATEGORY_DIALOG = "open_delete_category_dialog",
  CLOSE_DELETE_CATEGORY_DIALOG = "close_delete_category_dialog",
  SET_FIELD = "set_field",
  SET_ROLE_LIST = "set_role_list",
  SET_ARTICLE_TYPE_LIST = "set_article_type_list",
  SET_ARTICLE_LIST = "set_article_list",
  SET_ARTICLE_CATEGORY_LIST = "set_category_article_list",
  SET_OBJECT = "set_object",
  SET_RELOAD_NEEDED = "set_reload_needed",
}

export default actions;
