import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { Toolbar } from "primereact/toolbar";
import React, { useContext } from "react";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { CategoryContext } from "../CategoryListContainer";
import DeleteCategoryContainer from "./delete-dialog/DeleteCategoryContainer";

interface DeleteCategoryDialogProps {
  closeDeleteCategoryDialog: () => void;
  deleteCategory: () => void;
}

export default function DeleteCategoryDialog({ closeDeleteCategoryDialog, deleteCategory }: DeleteCategoryDialogProps) {
  const {
    state: { object, delete_category_dialog_visible },
    messages,
  } = useContext(CategoryContext);

  const header = () => {
    const display = object ? object.name : Labels.EMPTY_STRING;
    return Labels.ARTICLE_CATEGORY_DELETE + display;
  };

  const buttonIcon = () => {
    return "pi pi-trash";
  };

  const leftButton = () => {
    return <Button icon={buttonIcon()} className="dark-gray-button rounded-button mr-3" onClick={deleteCategory} label={Labels.BUTTON_DELETE} />;
  };

  const rightButton = () => {
    return <Button label={Labels.BUTTON_GIVE_UP} icon="pi pi-times" className="dark-gray-button rounded-button" onClick={closeDeleteCategoryDialog} />;
  };

  const footer = () => {
    return (
      <div className="mb-3">
        <Toolbar left={leftButton} right={rightButton} />
      </div>
    );
  };

  return (
    <Dialog style={{ width: "1000px" }} visible={delete_category_dialog_visible} onHide={closeDeleteCategoryDialog} header={header()} footer={footer()} closable={false}>
      <div className="absolute z-1 w-10">
        <Messages ref={messages} />
      </div>
      <DeleteCategoryContainer />
    </Dialog>
  );
}
