const apiBase = (window as any).REACT_APP_BACKEND_API_BASE;

const url = apiBase ? apiBase : "http://" + window.location.hostname + ":8080/kaja";
const api = url + "/api";

const article_category = api + "/article-category";
const enums = api + "/enum";
const list = "/list";
const api_statistic = api + "/statistic";

const Endpoint = {
  TEST: api + "/public/test",
  AUTHENTICATE: url + "/web/authenticate",
  RESET_PASSWORD: url + "/reset-password",

  ARTICLE_LIST: api + "/article/article-list",
  ARTICLE_LIST_ADMIN: api + "/article/article-list/admin",
  ARTICLE_LIST_BY_CATEGORY: api + "/article/web/article-list/article-category",
  ARTICLE_TYPE_LIST: api + "/enum/article-type-list",
  USER_LIST: api + "/user/user-list",

  ARTICLE_CATEGORY_LIST: article_category + list,
  ROLE_LIST: enums + "/role-list",

  ROLE_CODE: api + "/role-code/list",
  GENDER_LIST: api + "/enum/gender-list",

  KALTURA_THUMBNAIL_URL: "https://cdnapisec.kaltura.com/p/1/thumbnail/entry_id/",
  YOUTUBE_THUMBNAIL_URL: "http://img.youtube.com/vi/",

  STATISTIC_REPORT: api_statistic + "/statistic-list",
  STATISTIC_ARTICLE_REPORT: api_statistic + "/article-statistic-time",
};

export default Endpoint;
