import React, { useContext, useEffect } from "react";
import useArticleController from "../../../../controllers/article/useArticleController";
import actions from "../../../../infrastructure/system/hooks/crudReducer/actions";
import { handleAxiosCallError } from "../../../../infrastructure/Utils";
import { CategoryContext } from "../../CategoryListContainer";
import DeleteCategoryView from "./DeleteCategoryView";

export default function DeleteCategoryContainer() {
  const {
    state: { object },
    dispatch,
    showDialogMessage,
  } = useContext(CategoryContext);
  const { getArticleListByCategory } = useArticleController();

  useEffect(() => {
    if (object?.id) {
      getArticleListByCategory(object.id)
        .then((res: any) => {
          dispatch({ type: actions.SET_ARTICLE_LIST, value: res?.data?.data });
        })
        .catch((error: any) => handleAxiosCallError(showDialogMessage, error));
    }
  }, [dispatch, getArticleListByCategory, object?.id, showDialogMessage]);

  return <DeleteCategoryView />;
}
