import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/nova-accent/theme.css";
import "./layout/layout.scss";
import React from "react";
import "react-app-polyfill/ie11";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Store from "./Store";
import BlockUI from "./BlockUI";

const root = createRoot(document.getElementById("root")!);
root.render(
  <BrowserRouter>
    <Store>
      <BlockUI />
      <App />
    </Store>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
