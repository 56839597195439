import { Button } from "primereact/button";
import React from "react";
import { tooltipOptionsTop } from "../../../../infrastructure/Utils";

interface ButtonComponentProps {
  tooltip: string;
  icon: string;
  className?: string;
  onClick: () => void;
}

export default function ButtonComponent({ tooltip, icon, className = "gray-button", onClick }: ButtonComponentProps) {
  return <Button type="button" tooltip={tooltip} tooltipOptions={tooltipOptionsTop} className={`${className} rounded-button mr-3`} icon={`pi ${icon}`} onClick={onClick} />;
}
