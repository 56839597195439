import actions from "./actions";
import fieldNames from "./fieldNames";

export default function reducer(state: any, action: any) {
  switch (action.type) {
    case actions.OPEN_DIALOG: {
      return {
        ...state,
        [fieldNames.ENTITY_OPERATION]: action.value.entityOperation,
        [fieldNames.OBJECT]: action.value.object,
        [fieldNames.DIALOG_VISIBLE]: true,
      };
    }
    case actions.CLOSE_DIALOG: {
      return {
        ...state,
        [fieldNames.DIALOG_VISIBLE]: false,
      };
    }
    case actions.OPEN_DELETE_CATEGORY_DIALOG: {
      return {
        ...state,
        [fieldNames.DELETE_CATEGORY_DIALOG_VISIBLE]: true,
      };
    }
    case actions.CLOSE_DELETE_CATEGORY_DIALOG: {
      return {
        ...state,
        [fieldNames.DELETE_CATEGORY_DIALOG_VISIBLE]: false,
      };
    }
    case actions.SET_FIELD: {
      return {
        ...state,
        object: { ...state.object, [action.fieldName]: action.value },
      };
    }
    case actions.SET_OBJECT: {
      return {
        ...state,
        [fieldNames.OBJECT]: action.value,
      };
    }
    case actions.SET_ROLE_LIST: {
      return {
        ...state,
        [fieldNames.ROLE_LIST]: action.value,
      };
    }
    case actions.SET_ARTICLE_TYPE_LIST: {
      return {
        ...state,
        [fieldNames.ARTICLE_TYPE_LIST]: action.value,
      };
    }
    case actions.SET_ARTICLE_CATEGORY_LIST: {
      return {
        ...state,
        [fieldNames.ARTICLE_CATEGORY_LIST]: action.value,
      };
    }
    case actions.SET_ARTICLE_LIST: {
      return {
        ...state,
        [fieldNames.ARTICLE_LIST]: action.value,
      };
    }
    case actions.SET_RELOAD_NEEDED: {
      return {
        ...state,
        [fieldNames.RELOAD_NEEDED]: { reload_needed: action.value.reload_needed, message: action.value.message },
      };
    }
    default:
      return state;
  }
}
