import actions from "./actions";
import fieldNames from "./fieldNames";

export default function reducer(state: any, action: any) {
  switch (action.type) {
    case actions.SET_FIELD: {
      return {
        ...state,
        filter: { ...state.filter, [action.fieldName]: action.value },
      };
    }
    case actions.SET_REPORT_LIST: {
      return {
        ...state,
        [fieldNames.REPORT_LIST]: action.value,
      };
    }
    case actions.SET_ARTICLE_CATEGORY_LIST: {
      return {
        ...state,
        [fieldNames.ARTICLE_CATEGORY_LIST]: action.value,
      };
    }
    case actions.SET_RELOAD_NEEDED: {
      return {
        ...state,
        [fieldNames.RELOAD_NEEDED]: { reload_needed: action.value.reload_needed, message: action.value.message },
      };
    }
    default: {
      return state;
    }
  }
}
