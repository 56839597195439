import React, { ChangeEvent, useContext } from "react";
import Labels from "../../../../../infrastructure/Labels_sr_Latn_RS";
import { ArticleContext } from "../../../ArticleListContainer";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import objectFieldNames from "../../../../../infrastructure/system/hooks/crudReducer/objectFieldNames";
import EntityOperation from "../../../../../infrastructure/EnumEntityOperation";
import { getImage, IMAGE_UPLOAD_SIZE_LIMIT_1_MB } from "../../../../../infrastructure/Utils";
import { InputText } from "primereact/inputtext";
import { InputNumber, InputNumberValueChangeParams } from "primereact/inputnumber";
import ImageUploader from "react-images-upload";
import { Button } from "primereact/button";

interface ArticleBasicComponentProps {
  onChangeTextInput: (fieldName: string) => (event: ChangeEvent<HTMLInputElement> | InputNumberValueChangeParams) => void;
  onChangeDropdownValue: (fieldName: string, emptyFieldName?: string) => (event: DropdownChangeParams) => void;
  onDropPicture: (fieldName: string) => (picture: any) => void;
  onRemovePicture: (fieldName: string) => void;
}

export default function ArticleBasicComponent({ onChangeTextInput, onChangeDropdownValue, onDropPicture, onRemovePicture }: ArticleBasicComponentProps) {
  const {
    state: { object, entity_operation, article_type_list, article_category_list },
  } = useContext(ArticleContext);

  const disabled = [EntityOperation.READ, EntityOperation.DELETE].includes(entity_operation);
  const imageExtensions: Array<string> = [".jpg", ".gif", ".png", ".jpeg"];

  const renderArticleCategoryName = (e: any) => `${e?.name}`;

  return (
    <div className="scrollable-content">
      <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
        <div className="p-grid p-align-center p-nogutter">
          <div className="p-col-12">
            <div className="flex col-12">
              <div className="col-4">{Labels.ARTICLE_CATEGORY_AREA + Labels.SPECIAL_CHAR_REQUIRED}</div>
              <div className="col-6 p-fluid">
                <Dropdown
                  disabled={disabled}
                  value={object?.articleCategory}
                  options={article_category_list}
                  optionLabel="name"
                  emptyMessage={Labels.ARTICLE_CATEGORY_NO_RESULT}
                  itemTemplate={renderArticleCategoryName}
                  valueTemplate={renderArticleCategoryName}
                  onChange={onChangeDropdownValue(objectFieldNames.ARTICLE_CATEGORY)}
                />
              </div>
            </div>

            <div className="flex col-12">
              <div className="col-4">{Labels.ARTICLE_TYPE + Labels.SPECIAL_CHAR_REQUIRED}</div>
              <div className="col-6 p-fluid">
                <Dropdown
                  disabled={disabled}
                  value={object?.articleType}
                  options={article_type_list}
                  optionLabel="name"
                  optionValue="code"
                  onChange={onChangeDropdownValue(objectFieldNames.ARTICLE_TYPE, objectFieldNames.BODY)}
                />
              </div>
            </div>

            <div className="flex col-12">
              <div className="col-4">{Labels.ARTICLE_TITLE + Labels.SPECIAL_CHAR_REQUIRED}</div>
              <div className="col-6">
                <InputText disabled={disabled} value={object?.title ?? Labels.EMPTY_STRING} onChange={onChangeTextInput(objectFieldNames.TITLE)} />
              </div>
            </div>

            <div className="flex col-12">
              <div className="col-4">{Labels.ARTICLE_DISPLAY_ORDER}</div>
              <div className="col-2 w-10-rem">
                <InputNumber
                  disabled={disabled}
                  value={object?.displayOrder}
                  onValueChange={onChangeTextInput(objectFieldNames.DISPLAY_ORDER)}
                  min={1}
                  step={1}
                  showButtons
                  buttonLayout="horizontal"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  decrementButtonClassName="p-button-text"
                  incrementButtonClassName="p-button-text"
                  inputClassName="px-0"
                />
              </div>
            </div>
            <div className="flex col-12">
              <div className="col-4 flex align-items-center">
                {(object?.headerImage || entity_operation === EntityOperation.CREATE || entity_operation === EntityOperation.UPDATE) && <div>{Labels.ARTICLE_HEADER_IMAGE}</div>}
              </div>
              <div className="col-6">
                {(entity_operation === EntityOperation.UPDATE || entity_operation === EntityOperation.CREATE) && !object?.headerImage && (
                  <ImageUploader
                    singleImage={true}
                    withLabel={false}
                    withPreview={false}
                    withIcon={false}
                    buttonText={Labels.ARTICLE_ADD_IMAGE}
                    buttonClassName="upload-button"
                    onChange={onDropPicture(objectFieldNames.HEADER_IMAGE)}
                    imgExtension={imageExtensions}
                    maxFileSize={IMAGE_UPLOAD_SIZE_LIMIT_1_MB}
                    fileSizeError={Labels.MESSAGES_FILE_SIZE_ERROR}
                  />
                )}
                {object?.headerImage && (
                  <div className="image-container">
                    <img src={getImage(false, object?.headerImage, null)} alt="#" className="w-full image" />
                    {(entity_operation === EntityOperation.CREATE || entity_operation === EntityOperation.UPDATE) && (
                      <Button
                        icon="pi pi-trash large-button-font"
                        className="red-button large-button rounded-button mr-3 flex remove-image-button"
                        onClick={() => onRemovePicture(objectFieldNames.HEADER_IMAGE)}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="flex col-12">
              <div className="col-4 flex align-items-center">
                {(object?.summaryImage || entity_operation === EntityOperation.CREATE || entity_operation === EntityOperation.UPDATE) && <div>{Labels.ARTICLE_SUMMARY_IMAGE}</div>}
              </div>
              <div className="col-6">
                {(entity_operation === EntityOperation.UPDATE || entity_operation === EntityOperation.CREATE) && !object?.summaryImage && (
                  <>
                    <ImageUploader
                      singleImage={true}
                      withLabel={false}
                      withPreview={false}
                      withIcon={false}
                      buttonText={Labels.ARTICLE_ADD_IMAGE}
                      buttonClassName="upload-button"
                      onChange={onDropPicture(objectFieldNames.SUMMARY_IMAGE)}
                      imgExtension={imageExtensions}
                      maxFileSize={IMAGE_UPLOAD_SIZE_LIMIT_1_MB}
                      fileSizeError={Labels.MESSAGES_FILE_SIZE_ERROR}
                    />
                  </>
                )}
                {object?.summaryImage && (
                  <div className="image-container">
                    <img src={getImage(false, object?.summaryImage, null)} alt="#" className="w-full image" />
                    {(entity_operation === EntityOperation.CREATE || entity_operation === EntityOperation.UPDATE) && (
                      <Button
                        icon="pi pi-trash large-button-font"
                        className="red-button large-button rounded-button mr-3 flex remove-image-button"
                        onClick={() => onRemovePicture(objectFieldNames.SUMMARY_IMAGE)}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
