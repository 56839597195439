import React, { useContext, useEffect } from "react";
import useArticleController from "../../../controllers/article/useArticleController";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import actions from "../../../infrastructure/system/hooks/crudReducer/actions";
import { handleAxiosCallError, isEmptyString } from "../../../infrastructure/Utils";
import EnumArticleType from "../../../model/article/EnumArticleType";
import { ArticleContext } from "../ArticleListContainer";
import CrudArticleDialog from "./CrudArticleDialog";

export default function CrudArticleDialogContainer() {
  const {
    state: { entity_operation, object, dialog_visible },
    dispatch,
    setReloadNeeded,
    showDialogMessage,
  } = useContext(ArticleContext);
  const { createArticle, deleteArticle, updateArticle, getArticle } = useArticleController();
  const closeDialog = () => dispatch({ type: actions.CLOSE_DIALOG });

  useEffect(() => {
    if (dialog_visible && object?.id) {
      getArticle(object.id)
        .then((res) => {
          dispatch({ type: actions.SET_OBJECT, value: res.data.data });
        })
        .catch((error) => handleAxiosCallError(showDialogMessage, error));
    }
  }, [dialog_visible, object?.id, getArticle, dispatch, showDialogMessage]);

  const validateInput = () => {
    if (!object?.articleCategory) {
      showDialogMessage(MessageType.ERROR, Labels.ARTICLE_ARTICLE_CATEGORY_NOT_EMPTY);
      return false;
    }
    if (!object?.articleType) {
      showDialogMessage(MessageType.ERROR, Labels.ARTICLE_ARTICLE_TYPE_NOT_EMPTY);
      return false;
    }
    if (isEmptyString(object?.title)) {
      showDialogMessage(MessageType.ERROR, Labels.ARTICLE_NAME_NOT_EMPTY);
      return false;
    }
    if (object?.articleType === EnumArticleType.VIDEO && isEmptyString(object?.url)) {
      showDialogMessage(MessageType.ERROR, Labels.ARTICLE_URL_NOT_EMPTY);
      return false;
    }
    if (object?.articleType === EnumArticleType.VIDEO && !object?.url.includes("/embed/")) {
      showDialogMessage(MessageType.ERROR, Labels.ARTICLE_URL_MUST_BE_EMBED);
      return false;
    }
    return true;
  };

  const onCreateArticle = () => {
    if (!validateInput()) {
      return;
    }
    createArticle(object)
      .then(() => {
        closeDialog();
        setReloadNeeded(true, `${Labels.ARTICLE_CREATE_SUCCESSFUL} ${object.title}`);
      })
      .catch((error) => handleAxiosCallError(showDialogMessage, error));
  };

  const onDeleteArticle = () => {
    deleteArticle(object.id)
      .then(() => {
        closeDialog();
        setReloadNeeded(true, `${Labels.ARTICLE_DELETE_SUCCESSFUL} ${object.title}`);
      })
      .catch((error) => handleAxiosCallError(showDialogMessage, error));
  };

  const onUpdateArticle = () => {
    if (!validateInput()) {
      return;
    }
    updateArticle(object)
      .then(() => {
        closeDialog();
        setReloadNeeded(true, `${Labels.ARTICLE_UPDATE_SUCCESSFUL} ${object.title}`);
      })
      .catch((error) => handleAxiosCallError(showDialogMessage, error));
  };

  const doOnArticle = () => {
    switch (entity_operation) {
      case EntityOperation.CREATE:
        onCreateArticle();
        break;
      case EntityOperation.DELETE:
        onDeleteArticle();
        break;
      case EntityOperation.UPDATE:
        onUpdateArticle();
        break;
    }
  };

  return <CrudArticleDialog closeDialog={closeDialog} doOnArticle={doOnArticle} />;
}
