import { useContext, useState } from "react";
import { LoginController, LoginControllerType } from "../../controllers/login/LoginController";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import { handleAxiosCallError, handleAxiosCallSuccess, validateStringEmpty } from "../../infrastructure/Utils";
import ForgottenPasswordDto from "../../model/forgotten-password/ForgottenPasswordDto";
import { AppContext } from "../../Store";
import { useNavigate } from "react-router-dom";

interface ForgottenPasswordContainerType {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  resetPassword: () => void;
  returnToLoginPage: () => void;
}

export default function ForgottenPasswordContainer(): ForgottenPasswordContainerType {
  const [email, setEmail] = useState(Labels.EMPTY_STRING);
  const { showLoginMessage, setShowBlockUI } = useContext(AppContext);
  const { axiosResetPassword }: LoginControllerType = LoginController();
  const navigate = useNavigate();

  const validateEmailInput = () => {
    if (validateStringEmpty(email)) {
      return false;
    }

    return true;
  };

  const resetPassword = () => {
    if (!validateEmailInput()) {
      showLoginMessage(MessageType.ERROR, Labels.MESSAGES_EMAIL_REQUIRED);
      return false;
    }

    const forgottenPasswordDto: ForgottenPasswordDto = {
      email: email,
    };

    setShowBlockUI(true);
    axiosResetPassword(forgottenPasswordDto)
      .then((response: any) => {
        returnToLoginPage();
        handleAxiosCallSuccess(showLoginMessage, response);
      })
      .catch((e) => {
        handleAxiosCallError(showLoginMessage, e);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const returnToLoginPage = () => {
    navigate("/");
  };

  return {
    email,
    setEmail,
    resetPassword,
    returnToLoginPage,
  };
}

export type { ForgottenPasswordContainerType };
