import { InputNumberValueChangeParams } from "primereact/inputnumber";
import React, { ChangeEvent, useCallback, useContext } from "react";
import actions from "../../../../infrastructure/system/hooks/crudReducer/actions";
import { ArticleContext } from "../../ArticleListContainer";
import CrudArticleView from "./CrudArticleView";
import { DropdownChangeParams } from "primereact/dropdown";
import MessageType from "../../../../infrastructure/MessageType";
import Labels from "../../../../infrastructure/Labels_sr_Latn_RS";
import { EditorTextChangeParams } from "primereact/editor";

export default function CrudArticleContainer() {
  const {
    dispatch,
    showDialogMessage,
    state: { object },
  } = useContext(ArticleContext);

  const onChangeTextInput = useCallback(
    (fieldName: string) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | InputNumberValueChangeParams) => dispatch({ type: actions.SET_FIELD, fieldName, value: event.target.value }),
    [dispatch]
  );

  const onChangeEditorInput = useCallback(
    (fieldName: string) => (event: EditorTextChangeParams) => {
      dispatch({ type: actions.SET_FIELD, fieldName, value: event.htmlValue });
    },
    [dispatch]
  );

  const onChangeDropdownValue = useCallback(
    (fieldName: string, emptyFieldName?: string) => (event: DropdownChangeParams) => {
      dispatch({ type: actions.SET_OBJECT, value: { ...object, [fieldName]: event.value, ...(emptyFieldName ? { [emptyFieldName]: Labels.EMPTY_STRING } : {}) } });
    },
    [dispatch, object]
  );

  const onRemovePicture = (fieldName: string) => {
    dispatch({ type: actions.SET_FIELD, fieldName, value: undefined });
  };

  const onDropPicture = (fieldName: string) => (picture: any) => {
    let reader = new FileReader();
    if (picture[0]) {
      reader.readAsDataURL(picture[0]);
      reader.onload = function () {
        dispatch({ type: actions.SET_FIELD, fieldName: fieldName, value: reader.result?.toString().substring(reader.result?.toString().indexOf(",") + 1) });
      };
      reader.onerror = function (errorr) {
        showDialogMessage(MessageType.ERROR, Labels.MESSAGES_UPLOAD_IMAGE_ERROR);
      };
    } else {
      dispatch({ type: actions.SET_FIELD, fieldName: fieldName, value: undefined });
    }
  };

  return (
    <CrudArticleView
      onChangeTextInput={onChangeTextInput}
      onChangeDropdownValue={onChangeDropdownValue}
      onDropPicture={onDropPicture}
      onChangeEditorInput={onChangeEditorInput}
      onRemovePicture={onRemovePicture}
    />
  );
}
