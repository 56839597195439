import React from "react";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";

export default function AppHeader() {
  return (
    <div className="layout-app-header p-justify-center">
      <h1 className="header-text">{Labels.APPLICATION_NAME}</h1>
    </div >
  );
}
