import EntityOperation from "../../../EnumEntityOperation";
import fieldNames from "./fieldNames";

const initialState = {
  [fieldNames.DIALOG_VISIBLE]: false,
  [fieldNames.DELETE_CATEGORY_DIALOG_VISIBLE]: false,
  [fieldNames.ENTITY_OPERATION]: EntityOperation.CREATE,
  [fieldNames.OBJECT]: {},
  [fieldNames.ROLE_LIST]: [],
  [fieldNames.ARTICLE_TYPE_LIST]: [],
  [fieldNames.RELOAD_NEEDED]: { reload_needed: true },
};

export default initialState;
