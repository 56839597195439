import React, { useContext, useEffect } from "react";
import useArticleCategoryController from "../../../controllers/article-category/useArticleCategoryController";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import actions from "../../../infrastructure/system/hooks/crudReducer/actions";
import { handleAxiosCallError, isEmptyString } from "../../../infrastructure/Utils";
import { CategoryContext } from "../CategoryListContainer";
import CrudCategoryDialog from "./CrudCategoryDialog";
import DeleteCategoryDialog from "./DeleteCategoryDialog";

export default function CrudCategoryDialogContainer() {
  const {
    state: { entity_operation, object, dialog_visible, delete_category_dialog_visible },
    dispatch,
    setReloadNeeded,
    showDialogMessage,
  } = useContext(CategoryContext);
  const { createArticleCategory, deleteArticleCategory, updateArticleCategory, getArticleCategory } = useArticleCategoryController();

  const closeDialog = () => dispatch({ type: actions.CLOSE_DIALOG });

  const openDeleteCategoryDialog = () => dispatch({ type: actions.OPEN_DELETE_CATEGORY_DIALOG });
  const closeDeleteCategoryDialog = () => dispatch({ type: actions.CLOSE_DELETE_CATEGORY_DIALOG });

  useEffect(() => {
    if (dialog_visible && object?.id) {
      getArticleCategory(object.id)
        .then((res) => {
          dispatch({ type: actions.SET_OBJECT, value: res.data.data });
        })
        .catch((error) => handleAxiosCallError(showDialogMessage, error));
    }
  }, [dialog_visible, object?.id, getArticleCategory, dispatch, showDialogMessage]);

  const validateInput = () => {
    if (isEmptyString(object?.name)) {
      showDialogMessage(MessageType.ERROR, Labels.ARTICLE_CATEGORY_NAME_NOT_EMPTY);
      return false;
    }
    if (object?.displayOrder === undefined || object?.displayOrder === null) {
      showDialogMessage(MessageType.ERROR, Labels.ARTICLE_CATEGORY_DISPLAY_ORDER_NOT_EMPTY);
      return false;
    }
    if (object?.isMedicalArticle === undefined) {
      showDialogMessage(MessageType.ERROR, Labels.ARTICLE_CATEGORY_IS_MEDICAL_ARTICLE_NOT_EMPTY);
      return false;
    }
    if (object?.articleCategoryRole === undefined || object?.articleCategoryRole.length === 0) {
      showDialogMessage(MessageType.ERROR, Labels.ARTICLE_CATEGORY_VISIBILITY_NOT_EMPTY);
      return false;
    }
    return true;
  };

  const createCategory = () => {
    if (!validateInput()) {
      return;
    }
    createArticleCategory(object)
      .then(() => {
        closeDialog();
        setReloadNeeded(true, `${Labels.ARTICLE_CATEGORY_CREATE_SUCCESSFUL} ${object.name}`);
      })
      .catch((error) => handleAxiosCallError(showDialogMessage, error));
  };

  const deleteCategory = () => {
    deleteArticleCategory(object.id)
      .then(() => {
        closeDeleteCategoryDialog();
        setReloadNeeded(true, `${Labels.ARTICLE_CATEGORY_DELETE_SUCCESSFUL} ${object.name}`);
      })
      .catch((error) => handleAxiosCallError(showDialogMessage, error));
  };

  const updateCategory = () => {
    if (!validateInput()) {
      return;
    }
    updateArticleCategory(object)
      .then(() => {
        closeDialog();
        setReloadNeeded(true, `${Labels.ARTICLE_CATEGORY_UPDATE_SUCCESSFUL} ${object.name}`);
      })
      .catch((error) => handleAxiosCallError(showDialogMessage, error));
  };

  const doOnCategory = () => {
    switch (entity_operation) {
      case EntityOperation.CREATE:
        createCategory();
        break;
      case EntityOperation.DELETE:
        closeDialog();
        openDeleteCategoryDialog();
        break;
      case EntityOperation.UPDATE:
        updateCategory();
        break;
    }
  };

  return delete_category_dialog_visible ? (
    <DeleteCategoryDialog closeDeleteCategoryDialog={closeDeleteCategoryDialog} deleteCategory={deleteCategory} />
  ) : (
    <CrudCategoryDialog closeDialog={closeDialog} doOnCategory={doOnCategory} />
  );
}
