import React, { ChangeEvent, useContext, useCallback } from "react";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { StatisticContext } from "../StatisticContainer";
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import fieldNames from "../../../infrastructure/system/hooks/statisticReducer/fieldNames";
import actions from "../../../infrastructure/system/hooks/statisticReducer/actions";
import { Button } from "primereact/button";
import { addSrLocale, CALENDAR_DATE_FORMAT } from "../../../infrastructure/Utils";
import filterFieldNames from "../../../infrastructure/system/hooks/statisticReducer/filterFieldNames";

export default function FilterView() {
  addSrLocale();

  const { state, dispatch, getStatisticReport } = useContext(StatisticContext);

  const onChangeDateInput = useCallback(
    (fieldName: string) => (event: ChangeEvent<HTMLInputElement> | CalendarChangeParams) => dispatch({ type: actions.SET_FIELD, fieldName, value: event.target.value }),
    [dispatch]
  );

  return (
    <div className="layout-statistic">
      <div className="calendar">
        <div className="flex align-items-center justify-content-center sm:flex-column md:flex-column lg:flex-row xl:flex-row">
          <p className="sm:col-4 md:col-4 lg:col-2 xl:col-2  ">{Labels.STATISTIC_FILTER_START_DATE + Labels.SPECIAL_CHAR_REQUIRED}</p>
          <Calendar
            dateFormat={CALENDAR_DATE_FORMAT}
            value={state[fieldNames.FILTER][filterFieldNames.START_DATE]}
            onChange={onChangeDateInput(filterFieldNames.START_DATE)}
            showIcon
            className="sm:col-4 md:col-4 lg:col-3 xl:col-2"
          />
        </div>
        <div className="flex align-items-center justify-content-center sm:flex-column md:flex-column lg:flex-row xl:flex-row ">
          <p className="sm:col-4 md:col-4 lg:col-2 xl:col-2 ">{Labels.STATISTIC_FILTER_END_DATE}</p>
          <Calendar
            dateFormat={CALENDAR_DATE_FORMAT}
            value={state[fieldNames.FILTER][filterFieldNames.END_DATE]}
            onChange={onChangeDateInput(filterFieldNames.END_DATE)}
            showIcon
            className="sm:col-4 md:col-4 lg:col-3 xl:col-2"
          />
        </div>
      </div>
      <div className="mt-6 flex align-items-center justify-content-center">
        <Button label={Labels.STATISTIC_FILTER_SEARCH} onClick={getStatisticReport} className="pink-button rounded-button w-2" />
      </div>
    </div>
  );
}
