import { CheckboxChangeParams } from "primereact/checkbox";
import { InputNumberValueChangeParams } from "primereact/inputnumber";
import { RadioButtonChangeParams } from "primereact/radiobutton";
import React, { ChangeEvent, useCallback, useContext } from "react";
import Labels from "../../../../infrastructure/Labels_sr_Latn_RS";
import actions from "../../../../infrastructure/system/hooks/crudReducer/actions";
import objectFieldNames from "../../../../infrastructure/system/hooks/crudReducer/objectFieldNames";
import EnumRole from "../../../../model/EnumRole";
import { PageType } from "../../../../model/PageType";
import { CategoryContext } from "../../CategoryListContainer";
import CrudCategoryView from "./CrudCategoryView";

export default function CrudCategoryContainer() {
  const { dispatch, state } = useContext(CategoryContext);

  const pageChoices: PageType[] = [
    { name: Labels.INFO_PAGE_LABEL, key: Labels.INFO_PAGE_KEY, value: false },
    { name: Labels.MEDICAL_PAGE_LABEL, key: Labels.MEDICAL_PAGE_KEY, value: true },
  ];

  const onChangeTextInput = useCallback(
    (fieldName: string) => (event: ChangeEvent<HTMLInputElement> | InputNumberValueChangeParams) => dispatch({ type: actions.SET_FIELD, fieldName, value: event.target.value }),
    [dispatch]
  );

  const onChangeChecbox = useCallback(
    (event: CheckboxChangeParams) => {
      const roleListOld = state.object?.articleCategoryRole ?? [];
      dispatch({
        type: actions.SET_FIELD,
        fieldName: objectFieldNames.ROLE_LIST,
        value: event.checked ? [...roleListOld, { role: event.value }] : roleListOld.filter((aR: { role: string }) => aR.role !== event.value),
      });
    },
    [state.object?.articleCategoryRole, dispatch]
  );

  const onChangeRadioButton = useCallback(
    (event: RadioButtonChangeParams) => {
      dispatch({
        type: actions.SET_FIELD,
        fieldName: objectFieldNames.IS_MEDICAL_ARTICLE,
        value: event.value,
      });
      dispatch({
        type: actions.SET_FIELD,
        fieldName: objectFieldNames.ROLE_LIST,
        value: event.value ? [{ role: EnumRole.DOCTOR }] : [],
      });
    },
    [dispatch]
  );

  return <CrudCategoryView onChangeTextInput={onChangeTextInput} onChangeChecbox={onChangeChecbox} onChangeRadioButton={onChangeRadioButton} pageChoices={pageChoices} />;
}
