const Images = {
  LOGO: require("./images/logo/logo.png"),
  TWITTER: require("./images/twitter.png"),

  check: require("./images/check.png"),
  googlePlay: require("./images/googlePlay.png"),
  appStore: require("./images/appStore.png"),
  no_profile: require("./images/no_profile.png"),
  male: require("./images/male.jpg"),
  female: require("./images/female.jpg"),
  BRINK_PINK_BACKGRROUND: require("./images/brink_pink_background.png"),

  STATISTIC_REPORT_ICONS: {
    REGISTRATION: require("./images/new_user.png"),
    ENTER_CODE_PATIENT: require("./images/woman.png"),
    ENTER_CODE_DOCTOR: require("./images/woman_doctor.png"),
    READ_ARTICLE: require("./images/view.png"),
    FILLING_OUT_FORM: require("./images/list_check.png"),
  },
};

export default Images;
