import React, { useState, useRef, MutableRefObject, useCallback } from "react";
import AuthData from "./AuthData";
import UserReadDto from "./model/user/UserReadDto";
import { Messages, MessagesSeverityType } from "primereact/messages";
import Labels from "./infrastructure/Labels_sr_Latn_RS";

interface AppContextProps {
  authData: AuthData;
  updateAuthData: (data: string) => void;
  deleteAuthData: () => void;
  messages: MutableRefObject<any>;
  showMessage: (severity: MessagesSeverityType, summary: string, detail?: string, life?: number) => void;
  logInMessages: MutableRefObject<any>;
  showLoginMessage: (severity: MessagesSeverityType, summary: string, detail?: string, life?: number) => void;
  showBlockUI: boolean;
  setShowBlockUI: any;
}

export const AppContext = React.createContext({} as AppContextProps);

const Store = (props: any) => {
  const [authData, setAuthData] = useState(localStorage.getItem("kaja-app-auth") || Labels.EMPTY_STRING);
  const messages = useRef<Messages>(null);
  const logInMessages = useRef<Messages>(null);
  const [showBlockUI, setShowBlockUI] = useState(false);

  const showMessage = useCallback((severity: MessagesSeverityType, summary: string, detail?: string) => {
    try {
      messages?.current?.show({
        severity: severity,
        summary: summary,
        detail: detail,
        closable: true,
        sticky: false,
        life: 3000,
      });
    } catch {
      //navigate("/error");
    }
  }, []);

  const setBlockUI = useCallback((value: boolean) => {
    if (value) {
      setShowBlockUI(value);
    } else {
      setTimeout(() => {
        setShowBlockUI(value);
      }, 500);
    }
  }, []);

  const updateAuthData = (data: string) => {
    localStorage.setItem("kaja-app-auth", data);
    setAuthData(data);
  };

  const deleteAuthData = () => {
    setAuthData(Labels.EMPTY_STRING);
    localStorage.removeItem("kaja-app-auth");
  };

  const showLoginMessage = useCallback((severity: MessagesSeverityType, summary: string, detail?: string) => {
    try {
      logInMessages?.current?.show({
        severity: severity,
        summary: summary,
        detail: detail,
        closable: true,
        sticky: true,
      });
    } catch {
      //navigate("/error");
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        authData: authData !== Labels.EMPTY_STRING ? (JSON.parse(authData) as AuthData) : new AuthData("", new UserReadDto(0, "", "", "", "", false, "", "")),
        updateAuthData,
        deleteAuthData,
        messages,
        showMessage,
        logInMessages,
        showLoginMessage,
        showBlockUI: showBlockUI,
        setShowBlockUI: setBlockUI,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default Store;
