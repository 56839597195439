import axios, { AxiosResponse } from "axios";
import { useCallback, useContext } from "react";
import Endpoint from "../infrastructure/Endpoint";
import { axiosConfig } from "../infrastructure/Utils";
import { AppContext } from "../Store";

interface useEnumControllerType {
  getRoleList: () => Promise<AxiosResponse<any>>;
  getArticlesTypeList: () => Promise<AxiosResponse<any>>;
  getGenders: () => Promise<AxiosResponse<any>>;
}

export default function useEnumController(): useEnumControllerType {
  const { authData } = useContext(AppContext);

  const getRoleList = useCallback(() => {
    return axios.get(Endpoint.ROLE_LIST, axiosConfig(authData.token));
  }, [authData.token]);

  const getArticlesTypeList = useCallback(() => {
    return axios.get(Endpoint.ARTICLE_TYPE_LIST, axiosConfig(authData.token));
  }, [authData.token]);

  const getGenders = useCallback(() => {
    return axios.get(Endpoint.GENDER_LIST, axiosConfig(authData.token));
  }, [authData.token]);

  return {
    getRoleList,
    getArticlesTypeList,
    getGenders
  };
}
