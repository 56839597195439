import moment from "moment";
import React, { Dispatch, useContext, useReducer, useState, useCallback } from "react";
import { StatisticController } from "../../controllers/statistic/StatisticController";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../infrastructure/MessageType";
import actions from "../../infrastructure/system/hooks/statisticReducer/actions";
import fieldNames from "../../infrastructure/system/hooks/statisticReducer/fieldNames";
import filterFieldNames from "../../infrastructure/system/hooks/statisticReducer/filterFieldNames";
import initialState from "../../infrastructure/system/hooks/statisticReducer/initialState";
import reducer from "../../infrastructure/system/hooks/statisticReducer/reducer";
import { handleAxiosCallError } from "../../infrastructure/Utils";
import { AppContext } from "../../Store";
import StatisticView from "./StatisticView";

interface StatisticContextProps {
  state: any;
  dispatch: Dispatch<{ type: string; fieldName?: string; value?: any }>;
  getStatisticReport: () => void;
  isDialogVisible: boolean;
  setDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setReloadNeeded: (value: boolean, message?: string) => void;
}

export const StatisticContext = React.createContext({} as StatisticContextProps);

export default function StatisticContainer() {
  const { showMessage } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isDialogVisible, setDialogVisible] = useState<boolean>(false);

  const { axiosGetStatisticReport } = StatisticController();

  const validateInput = () => {
    if (!state[fieldNames.FILTER][filterFieldNames.START_DATE]) {
      showMessage(MessageType.ERROR, Labels.STATISTIC_FILTER_START_DATE_NOT_EMPTY);
      return false;
    }
    if (state[fieldNames.FILTER][filterFieldNames.END_DATE] && moment(state[fieldNames.FILTER][filterFieldNames.END_DATE]).isBefore(state[fieldNames.FILTER][filterFieldNames.START_DATE])) {
      showMessage(MessageType.ERROR, Labels.STATISTIC_FILTER_DATE_RANGE_ERROR);
      return false;
    }

    return true;
  };

  const getStatisticReport = () => {
    if (validateInput()) {
      axiosGetStatisticReport(state[fieldNames.FILTER])
        .then((res) => {
          dispatch({ type: actions.SET_REPORT_LIST, value: res.data.data });
        })
        .catch((err: any) => {
          handleAxiosCallError(showMessage, err);
        });
    }
  };

  const setReloadNeeded = useCallback((value: boolean, message?: string) => dispatch({ type: actions.SET_RELOAD_NEEDED, value: { reload_needed: value, message } }), []);

  return (
    <StatisticContext.Provider
      value={{
        state,
        dispatch,
        getStatisticReport,
        isDialogVisible,
        setDialogVisible,
        setReloadNeeded,
      }}
    >
      <StatisticView />
    </StatisticContext.Provider>
  );
}
