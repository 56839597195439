import React, { Dispatch, MutableRefObject, useContext, useReducer, useEffect, useCallback } from "react";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import { handleAxiosCallError } from "../../infrastructure/Utils";
import ArticleReadDto from "../../model/article/ArticleReadDto";
import { AppContext } from "../../Store";
import useEnumController from "../../controllers/useEnumController";
import { Messages, MessagesSeverityType } from "primereact/messages";
import reducer from "../../infrastructure/system/hooks/crudReducer/reducer";
import initialState from "../../infrastructure/system/hooks/crudReducer/initialState";
import actions from "../../infrastructure/system/hooks/crudReducer/actions";
import ArticleListView from "./ArticleListView";
import useArticleCategoryController from "../../controllers/article-category/useArticleCategoryController";
import useShowDialogMessage from "../../infrastructure/system/hooks/useShowDialogMessage";

interface ArticleContextProps {
  state: any;
  dispatch: Dispatch<{ type: string; fieldName?: string; value?: any }>;
  openDialog: (entityOperation: EntityOperation, row?: ArticleReadDto) => () => void;
  setReloadNeeded: (value: boolean, message?: string) => void;
  messages: MutableRefObject<Messages | null>;
  showDialogMessage: (severity: MessagesSeverityType, summary: string, detail?: string) => void;
}

export const ArticleContext = React.createContext({} as ArticleContextProps);

export default function ArticleListContainer() {
  const { showMessage } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getArticlesTypeList } = useEnumController();
  const { getArticleCategoryList } = useArticleCategoryController();

  useEffect(() => {
    getArticlesTypeList()
      .then((res) => {
        dispatch({ type: actions.SET_ARTICLE_TYPE_LIST, value: res.data });
      })
      .catch((error) => handleAxiosCallError(showMessage, error));

    getArticleCategoryList()
      .then((res) => {
        dispatch({ type: actions.SET_ARTICLE_CATEGORY_LIST, value: res.data?.data });
      })
      .catch((error) => handleAxiosCallError(showMessage, error));
  }, [getArticlesTypeList, getArticleCategoryList, showMessage]);

  const openDialog = (entityOperation: EntityOperation, row?: ArticleReadDto) => () => dispatch({ type: actions.OPEN_DIALOG, value: { entityOperation, object: row } });

  const setReloadNeeded = useCallback((value: boolean, message?: string) => dispatch({ type: actions.SET_RELOAD_NEEDED, value: { reload_needed: value, message } }), []);

  const { messages, showDialogMessage } = useShowDialogMessage();

  return (
    <ArticleContext.Provider
      value={{
        state,
        dispatch,
        openDialog,
        setReloadNeeded,
        messages,
        showDialogMessage,
      }}
    >
      <ArticleListView />
    </ArticleContext.Provider>
  );
}
