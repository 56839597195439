import React, { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { AppContext } from "../../../Store";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import MenuResizableContainer from "./menu-resizable/MenuResizableContainer";
import UserMenuContainer from "./user-menu/UserMenuContainer";
import PersonalDataDialogContainer from "./user-menu/components/dialog-user-data/PersonalDataDialogContainer";
import ServiceDataDialogContainer from "./user-menu/components/dialog-user-service/ServiceDataDialogContainer";

export default function AppTopbar() {
  const { deleteAuthData } = useContext(AppContext);
  const navigate = useNavigate();

  const [showPersonalDataDialog, setShowPersonalDataDialog] = useState<boolean>(false);
  const [showServiceDataDialog, setShowServiceDataDialog] = useState<boolean>(false);

  const logOutDialog = () => {
    confirmDialog({
      acceptLabel: Labels.BUTTON_LOGOUT,
      rejectLabel: Labels.BUTTON_GIVE_UP,
      rejectClassName: "dark-gray-button rounded-button",
      acceptClassName: "dark-gray-button rounded-button",
      rejectIcon: "pi pi-times",
      acceptIcon: "pi pi-power-off",
      message: Labels.LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED,
      accept: () => {
        deleteAuthData();
        navigate("/login");
      },
    });
  };

  return (
    <div className="layout-header">
      <div className="min-width-801">
        <div className="grid full-width align-items-center pt-3">
          <div className="col-11 d-flex justify-content-center">
            <div className="grid">
              <NavLink to="/">
                <div className={`button-bottom-border pb-2`}>{Labels.MENU_STATISTIC.toUpperCase()}</div>
              </NavLink>
              <NavLink to="/category-list">
                <div className={`ml-4 button-bottom-border pb-2`}>{Labels.MENU_CATEGORY_LIST.toUpperCase()}</div>
              </NavLink>
              <NavLink to="/article">
                <div className={`mx-4 button-bottom-border pb-2`}>{Labels.MENU_ARTICLE_LIST.toUpperCase()}</div>
              </NavLink>
              <NavLink to="/user-administration-list">
                <div className={`button-bottom-border pb-2`}>{Labels.MENU_USER_ADMINISTRATION.toUpperCase()}</div>
              </NavLink>
            </div>
          </div>
          <UserMenuContainer logOutDialog={logOutDialog} setShowPersonalDataDialog={setShowPersonalDataDialog} setShowServiceDataDialog={setShowServiceDataDialog} />
        </div>
      </div>
      <MenuResizableContainer logOutDialog={logOutDialog} setShowPersonalDataDialog={setShowPersonalDataDialog} setShowServiceDataDialog={setShowServiceDataDialog} />
      <ConfirmDialog />
      {showPersonalDataDialog && <PersonalDataDialogContainer showPersonalDataDialog={showPersonalDataDialog} setShowPersonalDataDialog={setShowPersonalDataDialog} />}
      {showServiceDataDialog && <ServiceDataDialogContainer showServiceDataDialog={showServiceDataDialog} setShowServiceDataDialog={setShowServiceDataDialog} />}
    </div>
  );
}
