import React, { useContext } from "react";
import { CategoryContext } from "../../CategoryListContainer";
import ArticleSmallReadDto from "../../../../model/article/ArticleSmallReadDto";
import Labels from "../../../../infrastructure/Labels_sr_Latn_RS";

export default function DeleteCategoryView() {
  const {
    state: { object, article_list },
  } = useContext(CategoryContext);

  const existArticleListForCategory: boolean = article_list !== undefined && article_list.length > 0;

  return (
    <div className="py-5 mt-4">
      {existArticleListForCategory && (
        <>
          <div>{Labels.ARTICLE_CATEGORY_DELETE_TEXT}</div>
          <ul>
            {article_list.map((article: ArticleSmallReadDto) => {
              return <li key={article.id}>{article.title}</li>;
            })}
          </ul>
        </>
      )}
      <div>
        {Labels.ARTICLE_CATEGORY_DELETE_CONFIRMATION} {object?.name}?
      </div>
    </div>
  );
}
