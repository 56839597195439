import React from "react";
import ArticleStatisticDialogContainer from "./article-dialog/ArticleStatisticDialogContainer";
import FilterView from "./filter/FilterView";
import ReportCardListContainer from "./report-card/ReportCardListContainer";

export default function StatisticView() {
  return (
    <div className="datatable-layout m-8 mt-5 px-5">
      <FilterView />
      <ReportCardListContainer />
      <ArticleStatisticDialogContainer />
    </div>
  );
}
