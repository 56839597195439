import { useContext, useState } from "react";
import AuthData from "../../AuthData";
import { LoginController, LoginControllerType } from "../../controllers/login/LoginController";
import LoginDataDto from "../../model/login/LoginDataDto";
import { AppContext } from "../../Store";
import { useNavigate } from "react-router-dom";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";

interface LoginContainerType {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  credentialNotValidMessage: string | undefined;
  postLogin: () => void;
  redirectToResetPasswordView: () => void;
}

export default function LoginContainer(): LoginContainerType {
  const { updateAuthData } = useContext(AppContext);
  const [email, setEmail] = useState(Labels.EMPTY_STRING);
  const [password, setPassword] = useState(Labels.EMPTY_STRING);
  const [credentialNotValidMessage, setCredentialNotValidMessage] = useState(undefined);
  const { axiosAuthenticateUser }: LoginControllerType = LoginController();
  const navigate = useNavigate();

  const postLogin = () => {
    const loginData: LoginDataDto = {
      email: email,
      password: password,
    };

    axiosAuthenticateUser(loginData)
      .then((result) => {
        const authData = new AuthData(result.data.data.jwt, result.data.data.user);
        updateAuthData(JSON.stringify(authData));
      })
      .catch((e) => {
        if (e.response.data.errors) {
          setCredentialNotValidMessage(e.response.data.errors[0].message);
        }
      });
  };

  const redirectToResetPasswordView = () => {
    navigate("/forgottenPassword");
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    credentialNotValidMessage,
    postLogin,
    redirectToResetPasswordView,
  };
}

export type { LoginContainerType };
