import React, { useContext, useEffect, useState } from "react";
import { UserController, UserControllerType } from "../../../../../../controllers/user/UserController";
import MessageType from "../../../../../../infrastructure/MessageType";
import { handleAxiosCallError, validateStringEmpty } from "../../../../../../infrastructure/Utils";
import UserUpdateDto from "../../../../../../model/user/UserUpdateDto";
import { AppContext } from "../../../../../../Store";
import PersonalDataDialog from "./PersonalDataDialog";
import Labels from "../../../../../../infrastructure/Labels_sr_Latn_RS";
import AuthData from "../../../../../../AuthData";
import useShowDialogMessage from "../../../../../../infrastructure/system/hooks/useShowDialogMessage";
import useEnumController from "../../../../../../controllers/useEnumController";
import { EnumDto } from "../../../../../../model/EnumDto";

interface PersonalDataDialogContainerProps {
  setShowPersonalDataDialog: React.Dispatch<React.SetStateAction<boolean>>;
  showPersonalDataDialog: boolean;
}

export default function PersonalDataDialogContainer({ setShowPersonalDataDialog, showPersonalDataDialog }: PersonalDataDialogContainerProps) {
  const { authData, showMessage, updateAuthData, setShowBlockUI } = useContext(AppContext);
  const [userChange, setUserChange] = useState<UserUpdateDto>(authData.currentUser);
  const [genderList, setGenderList] = useState<Array<EnumDto>>([]);
  const { axiosUpdateUser }: UserControllerType = UserController();
  const { getGenders } = useEnumController();
  const { messages, showDialogMessage } = useShowDialogMessage();

  useEffect(() => {
    getGenders()
      .then((res) => {
        setGenderList(res.data);
      })
      .catch((error) => handleAxiosCallError(showMessage, error));
  }, [getGenders, showMessage]);

  const validateInput = () => {
    if (validateStringEmpty(userChange?.firstname)) {
      showDialogMessage(MessageType.ERROR, Labels.USER_PROFILE_FIRST_NAME_REQUIRED);
      return false;
    }

    if (validateStringEmpty(userChange?.lastname)) {
      showDialogMessage(MessageType.ERROR, Labels.USER_PROFILE_LAST_NAME_REQUIRED);
      return false;
    }

    return true;
  };

  const closeDialog = () => {
    setShowPersonalDataDialog(false);
  };

  const updateUser = () => {
    if (userChange && validateInput()) {
      setShowBlockUI(true);
      axiosUpdateUser(userChange.id, userChange)
        .then((response) => {
          const authDataTmp = new AuthData(authData.token, response.data.data);
          updateAuthData(JSON.stringify(authDataTmp));
          closeDialog();
          showMessage(MessageType.SUCCESS, Labels.USER_PROFILE_USER_DATA_CHANGE_SUCCESS);
        })
        .catch((error: any) => {
          handleAxiosCallError(showDialogMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  return (
    <PersonalDataDialog
      closeDialog={closeDialog}
      showPersonalDataDialog={showPersonalDataDialog}
      userChange={userChange}
      setUserChange={setUserChange}
      updateUser={updateUser}
      messages={messages}
      genderList={genderList}
    />
  );
}
