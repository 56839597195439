import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import React, { MutableRefObject, useContext } from "react";
import Images from "../../../../infrastructure/Images";
import EnumGender from "../../../../model/EnumGender";
import MenuModel from "../../../../model/MenuModel";
import UserReadDto from "../../../../model/user/UserReadDto";
import { AppContext } from "../../../../Store";

interface UserMenuViewProps {
  menu: MutableRefObject<Menu | null>;
  menuModel: MenuModel[];
}

export default function UserMenuView({ menu, menuModel }: UserMenuViewProps) {
  const { authData } = useContext(AppContext);

  const renderAvatar = () => {
    const user: UserReadDto = authData.currentUser;
    return user?.gender ? (
      <Avatar image={user.gender === EnumGender.FEMALE ? Images.female : Images.male} className="avatar-size" shape="circle" />
    ) : (
      <Avatar label={(user?.firstname[0] + user?.lastname[0]).toUpperCase()} size="large" className="avatar-size" style={{ backgroundColor: "#4d5361", borderRadius: "50%" }} shape="circle" />
    );
  };

  return (
    <div className="col-1 d-flex justify-content-center">
      <div className="user-profile-avatar">
        <Menu ref={menu} model={menuModel} popup={true} />
        <div
          onClick={(event) => {
            menu?.current?.toggle(event);
          }}
        >
          {renderAvatar()}
        </div>
      </div>
    </div>
  );
}
