import { AxiosResponse } from "axios";
import moment from "moment";
import { Column, ColumnFilterElementTemplateOptions, ColumnFilterMatchModeType } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useContext } from "react";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import fieldNames from "../../../infrastructure/system/hooks/statisticReducer/fieldNames";
import { time, TIME_FORMAT_2 } from "../../../infrastructure/Utils";
import ButtonComponent from "../../common/datatable/buttons/ButtonComponent";
import DatatableContainer from "../../common/datatable/DatatableContainer";
import { StatisticContext } from "../StatisticContainer";
import articleStatisticColumnNames from "./articleStatisticColumnNames";

interface ArticleStatisticDialogProps {
  visible: boolean;
  hideDialog: () => void;
  getArticleStatisticReport: () => Promise<AxiosResponse<any, any>>;
}

export default function ArticleStatisticDialogView({ visible, hideDialog, getArticleStatisticReport }: ArticleStatisticDialogProps) {
  const { state, setReloadNeeded } = useContext(StatisticContext);

  const columnProps = {
    filter: true,
    filterMatchMode: "contains" as ColumnFilterMatchModeType,
    showFilterMenu: false,
  };

  const header = () => {
    return (
      <div className="flex flex-row justify-content-between align-items-center">
        <div>{Labels.STATISTIC_ARTICAL_REPORT}</div>
        <ButtonComponent icon="pi-times" onClick={hideDialog} tooltip={Labels.BUTTON_CANCEL} />
      </div>
    );
  };

  const statusRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={state[fieldNames.ARTICLE_CATEGORY_LIST]}
        optionLabel={"name"}
        optionValue={"name"}
        onChange={(e) => {
          console.log(e);
          options.filterApplyCallback(e.value);
        }}
        className="p-column-filter"
        showClear
      />
    );
  };

  const formatTotalTime = (articleStatistic: any) => {
    return <div>{time(moment(0).hours(0).seconds(articleStatistic.readingTime).toLocaleString(), TIME_FORMAT_2)}</div>;
  };

  const formatAvgTime = (articleStatistic: any) => {
    return <div>{time(moment(0).hours(0).seconds(articleStatistic.averageReadingTime).toLocaleString(), TIME_FORMAT_2)}</div>;
  };

  return (
    <Dialog style={{ width: "1500px" }} visible={visible} onHide={hideDialog} header={header} footer={<></>} closable={false}>
      <div className="datatable-layout mt-5 w-full">
        <div className="datatable-responsive">
          <DatatableContainer reload_needed={state.reload_needed} setReloadNeeded={setReloadNeeded} dataFetchCallback={getArticleStatisticReport}>
            <Column field={articleStatisticColumnNames.ARTICLE_CATEGORY} header={Labels.STATISTIC_ARTICAL_ARTICAL_CATEGORY} filter filterElement={statusRowFilterTemplate} showFilterMenu={false} />
            <Column field={articleStatisticColumnNames.ARTICLE} header={Labels.STATISTIC_ARTICAL_ARTICAL} {...columnProps} sortable />
            <Column field={articleStatisticColumnNames.TOTAL} header={Labels.STATISTIC_ARTICAL_TOTAL} sortable />
            <Column field={articleStatisticColumnNames.TIME} header={Labels.STATISTIC_ARTICAL_TIME} body={formatTotalTime} sortable />
            <Column field={articleStatisticColumnNames.AVG_TIME} header={Labels.STATISTIC_ARTICAL_AVG_TIME} body={formatAvgTime} sortable />
          </DatatableContainer>
        </div>
      </div>
    </Dialog>
  );
}
