import React, { ChangeEvent, useContext } from "react";
import { ArticleContext } from "../../../ArticleListContainer";
import objectFieldNames from "../../../../../infrastructure/system/hooks/crudReducer/objectFieldNames";
import EntityOperation from "../../../../../infrastructure/EnumEntityOperation";
import { toolbar } from "../../../../../infrastructure/Utils";
import { InputNumberValueChangeParams } from "primereact/inputnumber";
import EnumArticleType from "../../../../../model/article/EnumArticleType";
import { Editor, EditorTextChangeParams } from "primereact/editor";
import { InputText } from "primereact/inputtext";
import Labels from "../../../../../infrastructure/Labels_sr_Latn_RS";

interface ArticleSummaryComponentProps {
  onChangeTextInput: (fieldName: string) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | InputNumberValueChangeParams) => void;
  onChangeEditorInput: (fieldName: string) => (event: EditorTextChangeParams) => void;
}

export default function ArticleSummaryComponent({ onChangeTextInput, onChangeEditorInput }: ArticleSummaryComponentProps) {
  const {
    state: { object, entity_operation },
  } = useContext(ArticleContext);

  const disabled = [EntityOperation.READ, EntityOperation.DELETE].includes(entity_operation);

  return (
    <div className="scrollable-content">
      <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
        <div className="p-grid p-align-center p-nogutter">
          <div className="p-col-12">
            {object?.articleType === EnumArticleType.PLAIN_TEXT && (
              <div className="p-col-12 p-xl-12 p-lg-12 p-md-6 p-sm-12 tab-view">
                <div className="p-grid p-align-center p-nogutter">
                  <div className="p-col-12 p-fluid margin-top-bottom-5">
                    <Editor headerTemplate={<div></div>} readOnly={disabled} style={{ height: "320px" }} value={object?.body} onTextChange={onChangeEditorInput(objectFieldNames.BODY)} />
                  </div>
                </div>
              </div>
            )}
            {object?.articleType === EnumArticleType.VIDEO && (
              <div className="p-col-12 p-xl-12 p-lg-12 p-md-6 p-sm-12 tab-view">
                <div className="p-grid p-align-center p-nogutter">
                  <div className="flex col-12">
                    <div className="col-2 mt-2">{Labels.ARTICLE_URL + Labels.SPECIAL_CHAR_REQUIRED}</div>
                    <div className="col-10">
                      <InputText disabled={disabled} value={object?.url ?? Labels.EMPTY_STRING} onChange={onChangeTextInput(objectFieldNames.URL)} />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(object?.articleType === EnumArticleType.HTML || object?.articleType === EnumArticleType.VIDEO) && (
              <div className="p-col-12 p-xl-12 p-lg-12 p-md-6 p-sm-12 tab-view">
                <div className="p-grid p-align-center p-nogutter">
                  <div className="p-col-12 p-fluid margin-top-bottom-5">
                    <Editor
                      headerTemplate={<div></div>}
                      readOnly={disabled}
                      modules={toolbar}
                      style={{ height: "320px" }}
                      value={object?.body}
                      onTextChange={onChangeEditorInput(objectFieldNames.BODY)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
