import React from "react";
import { Dialog } from "primereact/dialog";
import Labels from "../../../../../../infrastructure/Labels_sr_Latn_RS";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Messages } from "primereact/messages";
import { Password } from "primereact/password";
import UserPasswordChangeDto from "../../../../../../model/user/UserPasswordChangeDto";

import Bowser from "bowser";
import BrowserName from "../../../../../../infrastructure/BrowserName";

interface ServiceDataDialogProps {
  closeDialog: () => void;
  showServiceDataDialog: boolean;
  passwordChange: UserPasswordChangeDto | undefined;
  setPasswordChange: React.Dispatch<React.SetStateAction<UserPasswordChangeDto | undefined>>;
  changePassword: () => void;
  messages: React.MutableRefObject<any>;
}

export default function ServiceDataDialog({ closeDialog, showServiceDataDialog, passwordChange, setPasswordChange, changePassword, messages }: ServiceDataDialogProps) {
  const leftButton = () => {
    return <Button label={Labels.BUTTON_SAVE_CHANGES} icon="pi pi-save" className="dark-gray-button rounded-button mr-3" onClick={changePassword} />;
  };

  const rightButton = () => {
    return <Button label={Labels.BUTTON_GIVE_UP} icon="pi pi-times" className="dark-gray-button rounded-button" onClick={closeDialog} />;
  };

  const footer = () => {
    return (
      <div className="mb-3">
        <Toolbar left={leftButton} right={rightButton} />
      </div>
    );
  };

  var result = Bowser.getParser(window.navigator.userAgent);
  var browserName = result.getBrowserName();
  return (
    <Dialog style={{ width: "800px" }} visible={showServiceDataDialog} onHide={closeDialog} header={Labels.USER_PROFILE_SERVICE_DATA} footer={footer()}>
      <div className="absolute z-1 w-10">
        <Messages ref={messages} />
      </div>
      <div className="flex align-items-center col-12 mt-7">
        <div className="col-3">{Labels.USER_PROFILE_OLD_PASSWORD}</div>
        <div className="col-6">
          <Password
            autoComplete="new-password"
            feedback={false}
            toggleMask={browserName === BrowserName.EDGE ? false : true}
            value={passwordChange?.currentPassword ?? Labels.EMPTY_STRING}
            onChange={(e) => {
              setPasswordChange({
                ...passwordChange!,
                currentPassword: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="flex align-items-center col-12">
        <div className="col-3">{Labels.USER_PROFILE_NEW_PASSWORD}</div>
        <div className="col-6">
          <Password
            autoComplete="new-password"
            feedback={false}
            toggleMask={browserName === BrowserName.EDGE ? false : true}
            value={passwordChange?.newPassword ?? Labels.EMPTY_STRING}
            onChange={(e) => {
              setPasswordChange({
                ...passwordChange!,
                newPassword: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="flex align-items-center col-12">
        <div className="col-3">{Labels.USER_PROFILE_CONFIRM_NEW_PASSWORD}</div>
        <div className="col-6">
          <Password
            autoComplete="new-password"
            feedback={false}
            toggleMask={browserName === BrowserName.EDGE ? false : true}
            value={passwordChange?.confirmPassword ?? Labels.EMPTY_STRING}
            onChange={(e) => {
              setPasswordChange({
                ...passwordChange!,
                confirmPassword: e.target.value,
              });
            }}
          />
        </div>
      </div>
    </Dialog>
  );
}
