import { Menu } from "primereact/menu";
import React, { useRef } from "react";
import Labels from "../../../../infrastructure/Labels_sr_Latn_RS";
import UserMenuView from "./UserMenuView";

interface UserMenuContainerProps {
  logOutDialog: () => void;
  setShowPersonalDataDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setShowServiceDataDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function UserMenuContainer({ logOutDialog, setShowPersonalDataDialog, setShowServiceDataDialog }: UserMenuContainerProps) {
  const menu = useRef<Menu>(null);

  const menuModel = [
    {
      items: [
        {
          label: Labels.APP_TOPBAR_PERSONAL_DATA,
          icon: "pi pi-user",
          command: () => setShowPersonalDataDialog(true),
        },
        {
          label: Labels.APP_TOPBAR_SERVICE_DATA,
          icon: "pi pi-lock",
          command: () => setShowServiceDataDialog(true),
        },
        {
          label: Labels.BUTTON_LOGOUT,
          icon: "pi pi-power-off",
          command: logOutDialog,
        },
      ],
    },
  ];

  return <UserMenuView menu={menu} menuModel={menuModel} />;
}
