import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import React from "react";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import ForgottenPasswordContainer, { ForgottenPasswordContainerType } from "./ForgottenPasswordContainer";
import { isEmptyString } from "../../infrastructure/Utils";

export default function ForgottenPasswordView() {
  const { email, setEmail, resetPassword, returnToLoginPage }: ForgottenPasswordContainerType = ForgottenPasswordContainer();

  return (
    <div className="layout-login">
      <div className="col-12 p-lg-6">
        <div className="col-12 panel-registration blabla d-flex justify-content-center">
          <Panel header={Labels.TITLE_FORGOTTEN_PASSWORD}>
            <div className="col-12 pb-3">
              <InputText
                placeholder={Labels.PLACEHOLDER_EMAIL_REQUIRED}
                value={email}
                onChange={(e: any) => {
                  setEmail(e.target.value);
                }}
                autoComplete="email"
              />
            </div>
            <div className="col-12 login-button">
              <Button
                label={Labels.BUTTON_RESET_PASSWORD}
                className="col-12"
                onClick={() => {
                  resetPassword();
                }}
                disabled={isEmptyString(email)}
              />
            </div>
            <div className="redirectButton">
              <Button label={Labels.RETURN_TO_LOGIN_PAGE} className="text-muted" onClick={returnToLoginPage} />
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
}
