import { Messages } from "primereact/messages";
import React, { useContext } from "react";
import { AppContext } from "../../Store";
import AppFooter from "../app/AppFooter";
import AppHeader from "../app/AppHeader";
import LoginContent from "./LoginContent";

export default function Login() {
  const { logInMessages } = useContext(AppContext);

  return (
    <div>
      <div className="d-grid p-fluid max-height">
        <div className="p-col-12">
          <AppHeader />
          <div className="top-border" />
        </div>
        <div className="p-col-12">
          <Messages ref={logInMessages} />
        </div>
        <div className="p-col-12 panel-registration">
          <div className="p-grid p-dir-row p-nogutter p-justify-center p-align-center">
            <LoginContent />
          </div>
        </div>
        <div className="p-col-12 p-col-nogutter flex-end">
          <AppFooter />
        </div>
      </div>
    </div>
  );
}
