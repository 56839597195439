import React, { useCallback, useContext, useEffect, useState } from "react";
import { RoleCodeController } from "../../../controllers/role-code/RoleCodeController";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import MessageType from "../../../infrastructure/MessageType";
import { handleAxiosCallError } from "../../../infrastructure/Utils";
import EnumRole from "../../../model/EnumRole";
import { AppContext } from "../../../Store";
import CodeAdministrationComponent from "./CodeAdministrationComponent";

interface CodeAdministrationContainerProp {
  role: string;
}

export default function CodeAdministrationContainer({ role }: CodeAdministrationContainerProp) {
  const label = role === EnumRole.DOCTOR ? Labels.USER_ADMINISTRATION_DOCTOR_CODE : Labels.USER_ADMINISTRATION_PATIENT_CODE;
  const { showMessage, setShowBlockUI } = useContext(AppContext);
  const [data, setData] = useState([]);
  const { axiosGetRoleCodeByRole, axiosDeleteRoleCode, axiosCreateRoleCode } = RoleCodeController();

  const fetchData = useCallback(() => {
    axiosGetRoleCodeByRole(role)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      });
  }, [axiosGetRoleCodeByRole, role, showMessage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const deleteRoleCode = (id: string) => () => {
    setShowBlockUI(true);
    axiosDeleteRoleCode(id)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.USER_ADMINISTRATION_DELETE_CODE_SUCCESS);
        fetchData();
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  const createRoleCode = () => {
    setShowBlockUI(true);
    axiosCreateRoleCode(role)
      .then(() => {
        showMessage(MessageType.SUCCESS, Labels.USER_ADMINISTRATION_CREATE_CODE_SUCCESS);
        fetchData();
      })
      .catch((error) => {
        handleAxiosCallError(showMessage, error);
      })
      .finally(() => {
        setShowBlockUI(false);
      });
  };

  return <CodeAdministrationComponent label={label} data={data} deleteRoleCode={deleteRoleCode} createRoleCode={createRoleCode} />;
}
