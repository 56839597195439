import React, { useMemo, useContext } from "react";
import StatisticReportDto from "../../../model/statistic/StatisticReportDto";
import { Image } from "primereact/image";
import EnumActionType from "../../../model/statistic/EnumActionType";
import { StatisticContext } from "../StatisticContainer";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";

interface ReportCardProps {
  report: StatisticReportDto;
  images: any;
}

export default function ReportCardView({ report, images }: ReportCardProps) {
  const { setDialogVisible, setReloadNeeded } = useContext(StatisticContext);

  const name: string = useMemo(() => {
    let temp = Labels.EMPTY_STRING;
    report.actionType.name.split(Labels.SPACE).forEach((word: string, index: number) => {
      temp += word;
      index % 2 === 0 ? (temp += Labels.SPACE) : (temp += Labels.NEW_LINE);
    });
    return temp;
  }, [report.actionType.name]);

  const onReportCardClick = () => {
    if (report.actionType.code === EnumActionType.READ_ARTICLE) {
      setDialogVisible(true);
      setReloadNeeded(true);
    }
  };

  return (
    <div className="xs:col-12 sm:col-12 md:col-12 lg:col-2 xl:col-2" onClick={onReportCardClick}>
      <div className="layout-statistic">
        <div className="report-card">
          <div className={"card border-round-3xl mt-8 h-20rem " + (report.actionType.code === EnumActionType.READ_ARTICLE ? "card-info" : Labels.EMPTY_STRING)}>
            <div className="flex align-items-center mx-auto mt-4 h-3rem ">
              <Image src={images[report.actionType.code]} />
            </div>
            <div className="mx-auto mt-5 ">
              <div className="count text-center flex-row">{report.count}</div>
              <div className="action mt-4 text-center ">{name}</div>
              <div className="action mt-3 text-center ">{report.actionType.code === EnumActionType.READ_ARTICLE && <i className="pi pi-info-circle info" />}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
