import actions from "./actions";
import fieldNames from "./fieldNames";


export default function reducer(state: any, action: any) {
    switch (action.type) {
        case actions.SET_DATA: {
            return {
                ...state,
                [fieldNames.DATA]: action.value
            }
        }
        case actions.ON_PAGE: {
            return {
                ...state,
                [fieldNames.FIRST]: action.value.first,
                [fieldNames.ROWS]: action.value.rows
            }
        }
        default:
            return state;
    }
}