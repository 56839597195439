import React from "react";
import { Dialog } from "primereact/dialog";
import Labels from "../../../../../../infrastructure/Labels_sr_Latn_RS";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import UserUpdateDto from "../../../../../../model/user/UserUpdateDto";
import { Messages } from "primereact/messages";
import { Dropdown } from "primereact/dropdown";
import { EnumDto } from "../../../../../../model/EnumDto";

interface PersonalDataDialogProps {
  closeDialog: () => void;
  showPersonalDataDialog: boolean;
  userChange: UserUpdateDto | undefined;
  setUserChange: React.Dispatch<React.SetStateAction<UserUpdateDto>>;
  updateUser: () => void;
  messages: React.MutableRefObject<any>;
  genderList: Array<EnumDto>;
}

export default function PersonalDataDialog({ closeDialog, showPersonalDataDialog, userChange, setUserChange, updateUser, messages, genderList }: PersonalDataDialogProps) {
  const leftButton = () => {
    return <Button label={Labels.BUTTON_SAVE_CHANGES} icon="pi pi-save" className="dark-gray-button rounded-button mr-3" onClick={updateUser} />;
  };

  const rightButton = () => {
    return <Button label={Labels.BUTTON_GIVE_UP} icon="pi pi-times" className="dark-gray-button rounded-button" onClick={closeDialog} />;
  };

  const footer = () => {
    return (
      <div className="mb-3">
        <Toolbar left={leftButton} right={rightButton} />
      </div>
    );
  };

  return (
    <Dialog style={{ width: "800px" }} visible={showPersonalDataDialog} onHide={closeDialog} header={Labels.USER_PROFILE_EDIT} footer={footer()}>
      <div className="absolute z-1 w-10">
        <Messages ref={messages} />
      </div>
      <div className="flex align-items-center col-12 mt-7">
        <div className="col-3">{Labels.USER_PROFILE_EMAIL}</div>
        <div className="col-6">
          <InputText value={userChange?.email ?? Labels.EMPTY_STRING} disabled />
        </div>
      </div>
      <div className="flex align-items-center col-12">
        <div className="col-3">{Labels.USER_PROFILE_FIRST_NAME}</div>
        <div className="col-6">
          <InputText
            value={userChange?.firstname ?? Labels.EMPTY_STRING}
            onChange={(e) => {
              setUserChange({
                ...userChange!,
                firstname: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="flex align-items-center col-12">
        <div className="col-3">{Labels.USER_PROFILE_LAST_NAME}</div>
        <div className="col-6">
          <InputText
            value={userChange?.lastname ?? Labels.EMPTY_STRING}
            onChange={(e) => {
              setUserChange({
                ...userChange!,
                lastname: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="flex align-items-center col-12">
        <div className="col-3">{Labels.USER_PROFILE_GENDER}</div>
        <div className="col-6">
          <Dropdown
            optionLabel="name"
            optionValue="code"
            options={genderList}
            value={userChange?.gender}
            resetFilterOnHide
            onChange={(e) => {
              setUserChange({
                ...userChange!,
                gender: genderList!.find((x: any) => x.code === e.value)?.code,
              });
            }}
            emptyMessage={Labels.USER_PROFILE_GENDER_NO_RESULTS}
          />
        </div>
      </div>
    </Dialog>
  );
}
