import React, { useContext } from "react";
import moment from "moment";
import { ArticleContext } from "../../../ArticleListContainer";
import { DATE_TIME_FORMAT, getImage } from "../../../../../infrastructure/Utils";
import Images from "../../../../../infrastructure/Images";
import { Card } from "primereact/card";

export default function ArticleShortOverviewComponent() {
  const {
    state: { object },
  } = useContext(ArticleContext);

  return (
    <div className="scrollable-content">
      <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
        <div className="flex justify-content-center">
          <div className="col-6">
            <Card>
              <div className="flex align-items-center">
                <div className="col-5">
                  {object?.summaryImage ? (
                    <>
                      <img src={getImage(false, object.summaryImage, null)} alt="#" className="card-img-top" />
                    </>
                  ) : (
                    <img
                      src={Images.LOGO}
                      alt="#"
                      style={{
                        width: "90%",
                        backgroundColor: "#f3c9cd",
                        borderRadius: "2rem",
                      }}
                    />
                  )}
                </div>
                <div className="col-7">
                  <p className="title-card">{object?.title}</p>
                  <div className="card-date-style">{moment(object?.insertTimestamp).format(DATE_TIME_FORMAT)}</div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
