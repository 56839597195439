import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { Toolbar } from "primereact/toolbar";
import React, { useContext } from "react";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { ArticleContext } from "../ArticleListContainer";
import CrudArticleContainer from "./crud/CrudArticleContainer";

interface CrudArticleDialogProps {
  closeDialog: () => void;
  doOnArticle: () => void;
}

export default function CrudArticleDialog({ closeDialog, doOnArticle }: CrudArticleDialogProps) {
  const {
    state: { object, entity_operation, dialog_visible },
    messages,
  } = useContext(ArticleContext);

  const header = () => {
    const display = object ? object.title : Labels.EMPTY_STRING;

    switch (entity_operation) {
      case EntityOperation.CREATE:
        return Labels.ARTICLE_CREATE;
      case EntityOperation.DELETE:
        return Labels.ARTICLE_DELETE + display;
      case EntityOperation.UPDATE:
        return Labels.ARTICLE_UPDATE + display;
      case EntityOperation.READ:
        return Labels.ARTICLE_READ + display;
      default:
        return Labels.EMPTY_STRING;
    }
  };

  const buttonLabel = () => {
    switch (entity_operation) {
      case EntityOperation.CREATE:
        return Labels.BUTTON_CREATE;
      case EntityOperation.DELETE:
        return Labels.BUTTON_DELETE;
      case EntityOperation.UPDATE:
        return Labels.BUTTON_UPDATE;
      case EntityOperation.READ:
        return Labels.BUTTON_DETAILS;
    }
  };

  const buttonIcon = () => {
    switch (entity_operation) {
      case EntityOperation.CREATE:
        return "pi pi-plus";
      case EntityOperation.DELETE:
        return "pi pi-trash";
      case EntityOperation.UPDATE:
        return "pi pi-pencil";
      case EntityOperation.READ:
        return Labels.EMPTY_STRING;
    }
  };

  const leftButton = () => {
    return entity_operation !== EntityOperation.READ && <Button icon={buttonIcon()} className="dark-gray-button rounded-button mr-3" onClick={doOnArticle} label={buttonLabel()} />;
  };

  const rightButton = () => {
    return (
      <Button label={entity_operation === EntityOperation.READ ? Labels.BUTTON_CANCEL : Labels.BUTTON_GIVE_UP} icon="pi pi-times" className="dark-gray-button rounded-button" onClick={closeDialog} />
    );
  };

  const footer = () => {
    return (
      <div className="mb-3">
        <Toolbar left={leftButton} right={rightButton} />
      </div>
    );
  };

  return (
    <Dialog style={{ width: "1000px" }} visible={dialog_visible} onHide={closeDialog} header={header()} footer={footer()} closable={false}>
      <Messages ref={messages} />
      <CrudArticleContainer />
    </Dialog>
  );
}
