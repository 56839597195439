import React, { useContext } from "react";
import Images from "../../../infrastructure/Images";
import fieldNames from "../../../infrastructure/system/hooks/statisticReducer/fieldNames";
import StatisticReportDto from "../../../model/statistic/StatisticReportDto";
import { StatisticContext } from "../StatisticContainer";
import ReportCardView from "./ReportCardView";

export default function ReportCardListContainer() {
  const { state } = useContext(StatisticContext);
  const images: any = Images.STATISTIC_REPORT_ICONS;

  return (
    <div className="flex justify-content-around sm:flex-column md:flex-column lg:flex-row xl:flex-row ">
      {state[fieldNames.REPORT_LIST].map((report: StatisticReportDto) => (
        <ReportCardView report={report} key={report.actionType.id} images={images} />
      ))}
    </div>
  );
}
