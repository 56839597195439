import "primeicons/primeicons.css";
import { Messages } from "primereact/messages";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/nova-accent/theme.css";
import React, { useContext } from "react";
import Content from "../Content";
import { AppContext } from "../Store";
import "./../layout/layout.scss";
import AppFooter from "./app/AppFooter";
import AppTopbar from "./app/app-topbar/AppTopbar";

export default function Home() {
  const { messages } = useContext(AppContext);

  return (
    <div className="p-grid max-height p-justify-between d-flex flex-column min-height">
      <div className="p-col-12">
        <AppTopbar />
      </div>
      <div className="p-col-12 absolute w-full mt-8 z-1">
        <Messages ref={messages} />
      </div>
      <div className="p-col-12 mt-4 flex-grow-1">
        <div className="p-grid p-dir-column p-justify-center">
          <div className="p-lg-8 p-md-8 p-sm-8 p-col-align-center">
            <Content />
          </div>
        </div>
      </div>
      <div className="p-col-12 ">
        <div className="p-col-12 p-col-nogutter ">
          <AppFooter />
        </div>
      </div>
    </div>
  );
}
