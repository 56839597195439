import React, { useContext, useEffect, useReducer } from "react";
import { AxiosResponse } from "axios";
import actions from "../../../infrastructure/system/hooks/datatableReducer/actions";
import initialState from "../../../infrastructure/system/hooks/datatableReducer/initialState";
import reducer from "../../../infrastructure/system/hooks/datatableReducer/reducer";
import DatatableComponent from "./DatatableComponent";
import { handleAxiosCallError } from "../../../infrastructure/Utils";
import { AppContext } from "../../../Store";
import MessageType from "../../../infrastructure/MessageType";

interface DatatableContainerProps {
  children: React.ReactNode;
  reload_needed: { reload_needed: boolean; message?: string };
  setReloadNeeded: (value: boolean) => void;
  dataFetchCallback: () => Promise<AxiosResponse<any>>;
  dataTableRef?: React.MutableRefObject<any>;
}

export default function DatatableContainer({ children, reload_needed, setReloadNeeded, dataFetchCallback, dataTableRef }: DatatableContainerProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showMessage, setShowBlockUI } = useContext(AppContext);

  useEffect(() => {
    if (reload_needed.reload_needed) {
      setShowBlockUI(true);
      dataFetchCallback()
        .then(({ data: { data } }) => {
          dispatch({ type: actions.SET_DATA, value: data });
          if (reload_needed.message) showMessage(MessageType.SUCCESS, reload_needed.message);
        })
        .catch((error) => handleAxiosCallError(showMessage, error))
        .finally(() => {
          setReloadNeeded(false);
          setShowBlockUI(false);
        });
    }
  }, [dataFetchCallback, reload_needed, setReloadNeeded, setShowBlockUI, showMessage]);

  return (
    <DatatableComponent state={state} dispatch={dispatch} dataTableRef={dataTableRef}>
      {children}
    </DatatableComponent>
  );
}
