import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { tooltipOptionsTop } from "../../../infrastructure/Utils";
import RoleCodeReadDto from "../../../model/role-code/RoleCodeReadDto";

interface CodeAdministrationComponentProps {
  label: string;
  data: Array<RoleCodeReadDto>;
  deleteRoleCode: (id: string) => () => void;
  createRoleCode: () => void;
}

export default function CodeAdministrationComponent({ label, data, deleteRoleCode, createRoleCode }: CodeAdministrationComponentProps) {
  const buttonListTemplate = (row: RoleCodeReadDto) => {
    return (
      <Button
        type="button"
        tooltip={Labels.USER_ADMINISTRATION_DELETE_CODE_TOOLTIP}
        tooltipOptions={tooltipOptionsTop}
        className={`dark-gray-button rounded-button`}
        icon="pi pi-trash"
        onClick={deleteRoleCode(row.id)}
      />
    );
  };

  return (
    <div className="m-4">
      <p className="flex justify-content-center"> {label}</p>

      <Button label={Labels.USER_ADMINISTRATION_GENERATE_NEW.toUpperCase()} className="pink-button rounded-button" onClick={createRoleCode} />
      <div className="mt-4 flex">
        <DataTable value={data} emptyMessage={Labels.USER_ADMINISTRATION_EMPTY_CODE_LIST_MESSAGE} className="w-full">
          <Column field="code" headerClassName="hidden" />
          <Column body={buttonListTemplate} headerClassName="hidden" />
        </DataTable>
      </div>
    </div>
  );
}
