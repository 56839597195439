import { Menu } from "primereact/menu";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Labels from "../../../../infrastructure/Labels_sr_Latn_RS";
import MenuResizableView from "./MenuResizableView";

interface MenuResizableContainerProps {
  logOutDialog: () => void;
  setShowPersonalDataDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setShowServiceDataDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MenuResizableContainer({ logOutDialog, setShowPersonalDataDialog, setShowServiceDataDialog }: MenuResizableContainerProps) {
  const navigate = useNavigate();

  const menuModel = [
    {
      label: Labels.MENU_STATISTIC,
      command: () => {
        navigate("/");
      },
    },
    {
      label: Labels.MENU_CATEGORY_LIST,
      command: () => {
        navigate("/category-list");
      },
    },
    {
      label: Labels.MENU_ARTICLE_LIST,
      command: () => {
        navigate("/article");
      },
    },
    {
      label: Labels.MENU_USER_ADMINISTRATION,
      command: () => {
        navigate("/user-administration-list");
      },
    },
    {
      label: Labels.APP_TOPBAR_PERSONAL_DATA,
      command: () => setShowPersonalDataDialog(true),
    },
    {
      label: Labels.APP_TOPBAR_SERVICE_DATA,
      command: () => setShowServiceDataDialog(true),
    },
    {
      label: Labels.BUTTON_LOGOUT,
      command: () => {
        logOutDialog();
      },
    },
  ];

  const menu = useRef<Menu>(null);

  return <MenuResizableView menu={menu} menuModel={menuModel} />;
}
