import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import Endpoint from "../../infrastructure/Endpoint";
import { axiosConfig } from "../../infrastructure/Utils";
import LoginDataDto from "../../model/login/LoginDataDto";
import ForgottenPasswordDto from "../../model/forgotten-password/ForgottenPasswordDto";
import { AppContext } from "../../Store";

interface LoginControllerType {
  axiosAuthenticateUser: (loginData: LoginDataDto) => Promise<AxiosResponse<any>>;
  axiosResetPassword: (forgottenPasswordDto: ForgottenPasswordDto) => Promise<AxiosResponse<any, any>>;
}

export function LoginController(): LoginControllerType {
  const { authData } = useContext(AppContext);

  const axiosAuthenticateUser = (loginData: LoginDataDto) => {
    return axios.post(Endpoint.AUTHENTICATE, loginData);
  };

  const axiosResetPassword = (forgottenPasswordDto: ForgottenPasswordDto) => {
    return axios.put(Endpoint.RESET_PASSWORD, forgottenPasswordDto, axiosConfig(authData.token));
  };

  return {
    axiosAuthenticateUser,
    axiosResetPassword,
  };
}

export type { LoginControllerType };
