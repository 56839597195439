import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/login/Login";
import { isPublicRoute } from "./infrastructure/Utils";
import PublicContent from "./PublicContent";
import { AppContext } from "./Store";

export default function App() {
  const { authData } = useContext(AppContext);

  const location = useLocation();

  if (isPublicRoute(location.pathname)) {
    return <PublicContent />;
  }

  return authData.token ? <Home /> : <Login />;
}
