import { Messages, MessagesSeverityType } from "primereact/messages";
import { useCallback, useRef } from "react";

export default function useShowDialogMessage() {
  const messages = useRef<Messages>(null);

  const showDialogMessage = useCallback((severity: MessagesSeverityType, summary: string, detail?: string) => {
    try {
      messages?.current?.show({
        severity: severity,
        summary: summary,
        closable: true,
        detail: detail,
        sticky: false,
        life: 3000,
      });
    } catch {}
  }, []);

  return { messages, showDialogMessage };
}
