import React, { useContext, useEffect } from "react";
import useArticleCategoryController from "../../../controllers/article-category/useArticleCategoryController";
import { StatisticController } from "../../../controllers/statistic/StatisticController";
import actions from "../../../infrastructure/system/hooks/statisticReducer/actions";
import fieldNames from "../../../infrastructure/system/hooks/statisticReducer/fieldNames";
import { handleAxiosCallError } from "../../../infrastructure/Utils";
import { AppContext } from "../../../Store";
import { StatisticContext } from "../StatisticContainer";
import ArticleStatisticDialogView from "./ArticleStatisticDialogView";

export default function ArticleStatisticDialogContainer() {
  const { showMessage } = useContext(AppContext);
  const { isDialogVisible, setDialogVisible, dispatch, state } = useContext(StatisticContext);
  const { getArticleCategoryList } = useArticleCategoryController();
  const { axiosGetArticleStatisticReport } = StatisticController();

  useEffect(() => {
    getArticleCategoryList()
      .then(({ data: { data } }: any) => {
        dispatch({ type: actions.SET_ARTICLE_CATEGORY_LIST, value: data });
      })
      .catch((error: any) => {
        handleAxiosCallError(showMessage, error);
      });
  }, [dispatch, showMessage, getArticleCategoryList]);

  const hideDialog = () => {
    setDialogVisible(false);
  };

  const getArticleStatisticReport = () => {
    return axiosGetArticleStatisticReport(state[fieldNames.FILTER]);
  };

  return <ArticleStatisticDialogView visible={isDialogVisible} hideDialog={hideDialog} getArticleStatisticReport={getArticleStatisticReport} />;
}
