import React, { useContext } from "react";
import { ArticleContext } from "../../../ArticleListContainer";
import { DATE_TIME_FORMAT, getImage } from "../../../../../infrastructure/Utils";
import EnumArticleType from "../../../../../model/article/EnumArticleType";
import parse from "html-react-parser";
import moment from "moment";
import Images from "../../../../../infrastructure/Images";

export default function ArticleContentOverviewComponent() {
  const {
    state: { object },
  } = useContext(ArticleContext);

  return (
    <div className="scrollable-content">
      <div className="p-col-8 p-xl-8 p-lg-8 p-md-6 p-sm-12 tab-view">
        <div className="p-grid p-align-center p-nogutter">
          <div className="p-col-12">
            <img src={getImage(false, object.headerImage, Images.BRINK_PINK_BACKGRROUND)} className="w-full" alt="#" />
            <div>
              <p className="article-content-title">{object?.title}</p>
              <div className="card-date-style pb-3">{moment(object?.insertTimestamp).format(DATE_TIME_FORMAT)}</div>
              {object?.url && object?.articleType === EnumArticleType.VIDEO && (
                <iframe
                  width="853"
                  height="480"
                  src={object.url}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              )}
              {object?.body && (object?.articleType === EnumArticleType.HTML || object?.articleType === EnumArticleType.VIDEO || object?.articleType === EnumArticleType.PLAIN_TEXT) && (
                <div className="scorpion-color">{parse(object.body)}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
