import React from "react";
import EnumRole from "../../model/EnumRole";
import CodeAdministrationContainer from "./code-administration/CodeAdministrationContainer";

export default function UserAdministrationListView() {
  const roles = [EnumRole.PATIENT, EnumRole.DOCTOR];

  return (
    <div className="layout-user-administration my-8 flex justify-content-center">
      <div className="flex flex-row justify-content-between w-5">
        {roles.map((role) => (
          <CodeAdministrationContainer key={role} role={role} />
        ))}
      </div>
      <div className="col-1" />
    </div>
  );
}
