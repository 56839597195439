import React from "react";
import { Routes, Route } from "react-router-dom";
import ArticleListContainer from "./components/article/ArticleListContainer";
import CategoryListContainer from "./components/category/CategoryListContainer";
import ErrorView from "./components/error/ErrorView";
import StatisticContainer from "./components/statistic/StatisticContainer";
import UserAdministrationListContainer from "./components/user-administration/UserAdministrationListContainer";

export default function Content() {
  return (
    <Routes>
      <Route path="/article" element={<ArticleListContainer />} />
      <Route path="/category-list" element={<CategoryListContainer />} />
      <Route path="/user-administration-list" element={<UserAdministrationListContainer />} />
      <Route path="/" element={<StatisticContainer />} />
      <Route path="*" element={<ErrorView />} />
    </Routes>
  );
}
