import { DataTable } from "primereact/datatable";
import { PaginatorTemplateOptions } from "primereact/paginator";
import React from "react";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import fieldNames from "../../../infrastructure/system/hooks/datatableReducer/fieldNames";
import PageDropdownTemplate from "../../../infrastructure/system/hooks/PageDropdownTemplate";

interface DatatableComponentProps {
  state: any;
  dispatch: any;
  children: any;
  dataTableRef?: React.MutableRefObject<any>;
}

export default function DatatableComponent({ state, dispatch, children, dataTableRef }: DatatableComponentProps) {
  const { rowsPerPageDropdownTemplateMedium } = PageDropdownTemplate();

  return (
    <DataTable
      filterDisplay="row"
      ref={dataTableRef}
      value={state[fieldNames.DATA]}
      first={state[fieldNames.FIRST]}
      rows={state[fieldNames.ROWS]}
      emptyMessage={Labels.LABEL_DATATABLE_EMPTY_MESSAGE}
      paginator
      paginatorTemplate={rowsPerPageDropdownTemplateMedium as PaginatorTemplateOptions}
    >
      {children}
    </DataTable>
  );
}
