import fieldNames from "./fieldNames";
import filterFieldNames from "./filterFieldNames";

const initialState = {
  [fieldNames.FILTER]: {
    [filterFieldNames.START_DATE]: undefined,
    [filterFieldNames.END_DATE]: undefined,
  },
  [fieldNames.REPORT_LIST]: [],
  [fieldNames.RELOAD_NEEDED]: { reload_needed: true },
  [fieldNames.ARTICLE_CATEGORY_LIST]: [],
};

export default initialState;
