enum fieldNames {
  DIALOG_VISIBLE = "dialog_visible",
  DELETE_CATEGORY_DIALOG_VISIBLE = "delete_category_dialog_visible",
  OBJECT = "object",
  ENTITY_OPERATION = "entity_operation",
  ROLE_LIST = "role_list",
  ARTICLE_TYPE_LIST = "article_type_list",
  ARTICLE_LIST = "article_list",
  ARTICLE_CATEGORY_LIST = "article_category_list",
  RELOAD_NEEDED = "reload_needed",
}

export default fieldNames;
