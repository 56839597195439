import React from "react";
import { Routes, Route } from "react-router-dom";
import ForgottenPasswordView from "../forgotten-password/ForgottenPasswordView";
import LoginView from "./LoginView";

export default function LoginContent() {
  return (
    <Routes>
      <Route path="/*" element={<LoginView />} />
      <Route path="/forgottenPassword" element={<ForgottenPasswordView />} />
    </Routes>
  );
}
