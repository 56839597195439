import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { Toolbar } from "primereact/toolbar";
import React, { useContext } from "react";
import EntityOperation from "../../../infrastructure/EnumEntityOperation";
import Labels from "../../../infrastructure/Labels_sr_Latn_RS";
import { CategoryContext } from "../CategoryListContainer";
import CrudCategoryContainer from "./crud/CrudCategoryContainer";

interface CrudCategoryDialogProps {
  closeDialog: () => void;
  doOnCategory: () => void;
}

export default function CrudCategoryDialog({ closeDialog, doOnCategory }: CrudCategoryDialogProps) {
  const {
    state: { object, entity_operation, dialog_visible, delete_category_dialog_visible },
    messages,
  } = useContext(CategoryContext);

  const header = () => {
    const display = object ? object.name : Labels.EMPTY_STRING;

    switch (entity_operation) {
      case EntityOperation.CREATE:
        return Labels.ARTICLE_CATEGORY_CREATE;
      case EntityOperation.DELETE:
        return (delete_category_dialog_visible ? Labels.ARTICLE_CATEGORY_DELETE_CONFIRMATION : Labels.ARTICLE_CATEGORY_DELETE) + display;
      case EntityOperation.UPDATE:
        return Labels.ARTICLE_CATEGORY_UPDATE + display;
      case EntityOperation.READ:
        return Labels.ARTICLE_CATEGORY_READ + display;
      default:
        return Labels.EMPTY_STRING;
    }
  };

  const buttonLabel = () => {
    switch (entity_operation) {
      case EntityOperation.CREATE:
        return Labels.BUTTON_CREATE;
      case EntityOperation.DELETE:
        return delete_category_dialog_visible ? Labels.BUTTON_DELETE : Labels.ARTICLE_CATEGORY_DELETE_CONFIRMATION_BUTTON_LABEL;
      case EntityOperation.UPDATE:
        return Labels.BUTTON_UPDATE;
      case EntityOperation.READ:
        return Labels.BUTTON_DETAILS;
    }
  };

  const buttonIcon = () => {
    switch (entity_operation) {
      case EntityOperation.CREATE:
        return "pi pi-plus";
      case EntityOperation.DELETE:
        return `pi ${delete_category_dialog_visible ? "pi-trash" : "pi-arrow-right"}`;
      case EntityOperation.UPDATE:
        return "pi pi-pencil";
      case EntityOperation.READ:
        return Labels.EMPTY_STRING;
    }
  };
  const leftButton = () => {
    return entity_operation !== EntityOperation.READ && <Button icon={buttonIcon()} className="dark-gray-button rounded-button mr-3" onClick={doOnCategory} label={buttonLabel()} />;
  };

  const rightButton = () => {
    return (
      <Button label={entity_operation === EntityOperation.READ ? Labels.BUTTON_CANCEL : Labels.BUTTON_GIVE_UP} icon="pi pi-times" className="dark-gray-button rounded-button" onClick={closeDialog} />
    );
  };

  const footer = () => {
    return (
      <div className="mb-3">
        <Toolbar left={leftButton} right={rightButton} />
      </div>
    );
  };

  return (
    <Dialog style={{ width: "1000px" }} visible={dialog_visible} onHide={closeDialog} header={header()} footer={footer()} closable={false}>
      <div className="absolute z-1 w-10">
        <Messages ref={messages} />
      </div>
      <CrudCategoryContainer />
    </Dialog>
  );
}
