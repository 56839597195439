import moment from "moment";
import EntityOperation from "./EnumEntityOperation";
import Labels from "./Labels_sr_Latn_RS";
import MessageType from "./MessageType";
import { addLocale, locale } from "primereact/api";

const isPublicRoute = (route: string) => {
  return route.startsWith("/public");
};

const stringChecker = (s: string | undefined | null) => {
  return s ? s : Labels.EMPTY_STRING;
};

const axiosConfig = (token: string, params?: Object) => {
  return {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: params ? params : {},
  };
};

const isFormDisabled = (operation: string) => {
  let returnValue = true;

  if (!operation) return true;

  switch (operation) {
    case EntityOperation.CREATE:
    case EntityOperation.UPDATE:
      returnValue = false;
      break;
    case EntityOperation.READ:
    case EntityOperation.DELETE:
      returnValue = true;
      break;
    default:
      returnValue = true;
      break;
  }

  return returnValue;
};

const toDate = (UTCDate: string, format: string) => {
  const timestamp = Date.parse(UTCDate);
  if (!isNaN(timestamp)) {
    const date = new Date(timestamp);
    return moment(date).format(format);
  } else {
    return "Lose zadat datum";
  }
};

const dateAndTime = (UTCDate: string) => {
  return toDate(UTCDate, DATE_TIME_FORMAT);
};

const date = (UTCDate: string) => {
  return toDate(UTCDate, DATE_FORMAT);
};

const time = (UTCDate: string, format?: string) => {
  return toDate(UTCDate, format ?? TIME_FORMAT);
};

const dateTimeBefore = (date: any, dateNow: any) => {
  const now = moment(dateNow);
  const durationAsMinutes = moment.duration(now.diff(date)).asMinutes();
  if (durationAsMinutes < 60) {
    return Labels.LABEL_BEFORE + Labels.SPACE + Math.floor(durationAsMinutes + 1) + Labels.SPACE + Labels.LABEL_MINUTES;
  } else if (durationAsMinutes < 24 * 60) {
    return Labels.LABEL_BEFORE + Labels.SPACE + Math.floor(durationAsMinutes / 60) + Labels.SPACE + Labels.LABEL_HOURS;
  } else {
    return moment(date).format(DATE_TIME_FORMAT);
  }
};

const imageExtention = (base64: string) => {
  if (base64) {
    switch (base64.charAt(0)) {
      case "/":
        return "jpg";
      case "i":
        return "png";
      case "R":
        return "gif";
      case "U":
        return "webp";
    }
  }
};

const getImage = (needUri: boolean, source: string, replaceImage: any) => {
  return source ? (needUri ? { uri: `data:image/${imageExtention(source)};base64,${source}` } : `data:image/${imageExtention(source)};base64,${source}`) : replaceImage;
};

const showActivityDuration = (seconds: number) => {
  return seconds ? new Date(seconds * 1000).toISOString().substr(11, 8) : Labels.EMPTY_STRING;
};

const handleAxiosCallSuccess = (showMessage: Function, response: any) => {
  if (response.data.data.successes) {
    showMessage(
      MessageType.SUCCESS,
      undefined,
      response.data.data.successes.map((e: any) => e.message + Labels.NEW_LINE)
    );
  } else {
    showMessage(MessageType.SUCCESS, undefined, response.toString());
  }
};

const handleAxiosCallError = (showMessage: Function, error: any) => {
  if (error.response.data.errors) {
    showMessage(
      MessageType.ERROR,
      undefined,
      error.response.data.errors.map((e: any) => e.message + Labels.NEW_LINE)
    );
  } else {
    showMessage(MessageType.ERROR, undefined, error.toString());
  }
};

const validateStringEmpty = (str: string | undefined) => !str || str.trim() === Labels.EMPTY_STRING;

const DATE_TIME_FORMAT = "DD.MM.YYYY. HH:mm";
const DATE_TIME_FORMAT_FULL = "DD.MM.YYYY. HH:mm:ss";
const DATE_FORMAT = "DD.MM.YYYY.";
const DATE_FORMAT2 = "YYYY-MM-DD";
const CALENDAR_DATE_FORMAT = "dd.mm.yy.";
const DATE_TIME_FORMAT_2 = "YYYY-MM-DD HH:mm:ss";
const DATE_TIME_FORMAT_3 = "YYYY-MM-DDTHH:mm:ss";
const TIME_FORMAT = "HH:mm";
const TIME_FORMAT_2 = "HH:mm:ss";

const quillColors = [
  "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
  "#bbbbbb",
  "#f06666",
  "#ffc266",
  "#ffff66",
  "#66b966",
  "#66a3e0",
  "#c285ff",
  "#888888",
  "#a10000",
  "#b26b00",
  "#b2b200",
  "#006100",
  "#0047b2",
  "#6b24b2",
  "#444444",
  "#5c0000",
  "#663d00",
  "#666600",
  "#003700",
  "#002966",
  "#3d1466",
  "#2a918b",
];

const toolbar = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ color: quillColors }, { background: quillColors }], // dropdown with defaults from theme
    [{ align: [] }],
    ["link", "image"],
  ],
};

const tooltipOptionsTop: any = { position: "top", hideDelay: 50 };
const tooltipOptionsBottom: any = { position: "bottom" };
const tooltipOptionsRight: any = { position: "right" };

const isEmptyString = (str: string | undefined) => !str || str.trim() === Labels.EMPTY_STRING;

const IMAGE_UPLOAD_SIZE_LIMIT_64_KB = 65500;
const IMAGE_UPLOAD_SIZE_LIMIT_1_MB = 1040000;

const addSrLocale = () => {
  addLocale("sr", {
    firstDayOfWeek: 1,
    dayNames: ["Nedelja", "Ponedeljak", "Utorak", "Sreda", "Četvrtak", "Petak", "Subota"],
    dayNamesShort: ["Ned", "Pon", "Uto", "Sre", "Čet", "Pet", "Sub"],
    dayNamesMin: ["N", "P", "U", "S ", "Č", "P ", "S"],
    monthNames: ["Januar", "Februar", "Mart", "April", "Maj", "Juni", "Juli", "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"],
    weekHeader: "Sedmica",
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: Labels.EMPTY_STRING,
    timeOnlyTitle: "Samo Vreme",
    timeText: "Vreme",
    hourText: "Sat",
    minuteText: "Minuta",
    secondText: "Sekunda",
    currentText: "Danas",
    ampm: false,
    month: "Mesec",
    week: "sedmica",
    day: "Dan",
    allDayText: "Celi Dan",
    clear: "Poništi",
    today: "Danas",
  });
  locale("sr");
};

export {
  isPublicRoute,
  getImage,
  showActivityDuration,
  imageExtention,
  dateTimeBefore,
  stringChecker,
  isFormDisabled,
  dateAndTime,
  date,
  time,
  axiosConfig,
  validateStringEmpty,
  CALENDAR_DATE_FORMAT,
  DATE_FORMAT,
  DATE_TIME_FORMAT_FULL,
  DATE_FORMAT2,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_2,
  DATE_TIME_FORMAT_3,
  TIME_FORMAT_2,
  handleAxiosCallError,
  handleAxiosCallSuccess,
  quillColors,
  toolbar,
  tooltipOptionsTop,
  tooltipOptionsBottom,
  tooltipOptionsRight,
  isEmptyString,
  IMAGE_UPLOAD_SIZE_LIMIT_64_KB,
  IMAGE_UPLOAD_SIZE_LIMIT_1_MB,
  addSrLocale,
};
