import { ColumnFilterMatchModeType, Column } from "primereact/column";
import React, { useContext } from "react";
import { Button } from "primereact/button";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { tooltipOptionsRight, date, time } from "../../infrastructure/Utils";
import ButtonComponent from "../common/datatable/buttons/ButtonComponent";
import DatatableContainer from "../common/datatable/DatatableContainer";
import columnNames from "./columnNames";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import CrudCategoryDialogContainer from "./crud-dialog/CrudCategoryDialogContainer";
import { CategoryContext } from "./CategoryListContainer";
import ArticleCategoryReadSmallDto from "../../model/article/category/ArticleCategoryReadSmallDto";
import useArticleCategoryController from "../../controllers/article-category/useArticleCategoryController";

export default function CategoryListView() {
  const { getArticleCategoryList } = useArticleCategoryController();
  const {
    openDialog,
    state: { reload_needed },
    setReloadNeeded,
  } = useContext(CategoryContext);

  const columnProps = {
    filter: true,
    filterMatchMode: "contains" as ColumnFilterMatchModeType,
    showFilterMenu: false,
  };

  const buttonListTemplate = (row: ArticleCategoryReadSmallDto) => {
    return (
      <div className="d-flex align-items-end">
        <ButtonComponent tooltip={Labels.ARTICLE_CATEGORY_TOOLTIP_UPDATE} icon="pi-pencil" onClick={openDialog(EntityOperation.UPDATE, row)} />
        <ButtonComponent tooltip={Labels.ARTICLE_CATEGORY_TOOLTIP_READ} icon="pi-info-circle" onClick={openDialog(EntityOperation.READ, row)} />
        <ButtonComponent tooltip={Labels.ARTICLE_CATEGORY_TOOLTIP_DELETE} icon="pi-trash" className="red-button" onClick={openDialog(EntityOperation.DELETE, row)} />
      </div>
    );
  };

  return (
    <div className="datatable-layout m-8 mt-5 px-5">
      <Button
        className="create-article-button rounded-button"
        icon="pi pi-plus"
        onClick={openDialog(EntityOperation.CREATE)}
        tooltip={Labels.ARTICLE_CATEGORY_TOOLTIP_CREATE}
        tooltipOptions={tooltipOptionsRight}
      />

      <div className="datatable-responsive">
        <DatatableContainer reload_needed={reload_needed} setReloadNeeded={setReloadNeeded} dataFetchCallback={getArticleCategoryList}>
          <Column field={columnNames.NAME} header={Labels.ARTICLE_CATEGORY_NAME} {...columnProps} sortable />
          <Column field={columnNames.DISPLAY_ORDER} header={Labels.ARTICLE_CATEGORY_DISPLAY_ORDER} {...columnProps} sortable />
          <Column
            field={columnNames.INSERT_TIMESTAMP}
            header={Labels.ARTICLE_CATEGORY_INSERT_TIMESTAMP}
            {...columnProps}
            body={(e) => (
              <>
                <p className="mb-0">{date(e[columnNames.INSERT_TIMESTAMP])}</p>
                <p className="mb-0">{time(e[columnNames.INSERT_TIMESTAMP])}</p>
              </>
            )}
            filter={false}
            className="w-2"
            sortable
          />
          <Column body={buttonListTemplate} />
        </DatatableContainer>
      </div>

      <CrudCategoryDialogContainer />
    </div>
  );
}
