const Labels = {
  NEW_LINE: "\n",
  TAB: "\t",
  SPACE: " ",
  DASH: " - ",
  SLASH: "/",
  EMPTY_STRING: "",
  SPECIAL_CHAR_REQUIRED: " *",

  // Application
  APPLICATION_NAME: "NIKA",

  // Common
  PRIVACY: "Privatnost",
  TERMS_OF_SERVICE: "Uslovi korišćenja aplikacije",
  PRIVACY_POLICY: "Politika privatnosti",
  COPYRIGHT: "Developed by   ",
  HELIANT: " HELIANT",
  LOGIN: "Prijava",
  FORGOTTEN_PASSWORD: "Zaboravili ste lozinku?",
  RETURN_TO_LOGIN_PAGE: "Povratak na prijavu",

  // Title labels
  TITLE_LOGIN: "Unesite Vaše pristupne parametre",
  TITLE_FORGOTTEN_PASSWORD: "Unesite Vašu imejl-adresu",

  // Menu labels
  MENU_CATEGORY_LIST: "Upravljanje kategorijama",
  MENU_ARTICLE_LIST: "Upravljanje sadržajem",
  MENU_USER_ADMINISTRATION: "Administracija korisnika",
  MENU_STATISTIC: "Statistika",

  // Button labels
  BUTTON_CREATE: "Kreiraj",
  BUTTON_UPDATE: "Izmeni",
  BUTTON_DETAILS: "Detalji",
  BUTTON_LOGIN: "PRIJAVI SE",
  BUTTON_RESET_PASSWORD: "Resetovanje lozinke",
  BUTTON_LOGOUT: "Odjava",
  BUTTON_CANCEL: "Zatvori",
  BUTTON_GIVE_UP: "Odustani",
  BUTTON_DELETE: "Obriši",
  BUTTON_SAVE_CHANGES: "Sačuvaj",

  // Placeholder labels
  PLACEHOLDER_PASSWORD: "lozinka",
  PLACEHOLDER_EMAIL_REQUIRED: "imejl-adresa",

  // Labels
  LABEL_BEFORE: "pre",
  LABEL_MINUTES: "minuta",
  LABEL_HOURS: "časova",
  LABEL_ARE_YOU_SURE_YOU_WANT_TO_PROCEED: "Da li ste sigurni da želite da se odjavite?",
  LABEL_DATATABLE_ROWS_PER_PAGE_DROPWDOWN_ALL: "Sve",
  LABEL_DATATABLE_EMPTY_MESSAGE: "Nema podataka",

  // Tabs
  TAB_ARTICLE_SUMMARY: "Osnovni podaci",
  TAB_ARTICLE_CONTENT: "Sadržaj",
  TAB_ARTICLE_PREVIEW_SUMMARY: "Kratak pregled",
  TAB_ARTICLE_PREVIEW_CONTENT: "Pregled sadržaja",

  // Messages
  MESSAGES_FILE_SIZE_ERROR: "Maksimalna veličina slike je 1 MB",
  MESSAGES_UPLOAD_IMAGE_ERROR: "Neuspešno učitvanje slike",
  MESSAGES_EMAIL_REQUIRED: "Polje 'imejl adresa' je obavezno!",

  // PasswordChange
  USER_PROFILE_PASSWORD_CHANGE_ERROR: "Greška prilikom promene lozinke.",
  USER_PROFILE_MESSAGES_NEW_PASSWORD: "Morate uneti novu lozinku.",
  USER_PROFILE_MESSAGES_OLD_PASSWORD: "Morate uneti staru lozinku.",
  USER_PROFILE_MESSAGES_PASSWORD_CONFIRMATION: "Morate uneti potvrdu nove lozinke.",
  USER_PROFILE_MESSAGES_PASSWORDS_NOT_SAME: "Nova lozinka i potvrda lozinke nisu iste.",
  USER_PROFILE_CHANGE_PASSWORD_SUCCESS: "Uspešno promenjena lozinka.",
  USER_PROFILE_EDIT: "Izmena korisničkog profila",
  USER_PROFILE_SERVICE_DATA: "Izmena servisnih podataka",
  USER_PROFILE_OLD_PASSWORD: "Stara lozinka",
  USER_PROFILE_NEW_PASSWORD: "Nova lozinka",
  USER_PROFILE_CONFIRM_NEW_PASSWORD: "Potvrda nove lozinke",
  USER_PROFILE_FIRST_NAME: "Ime",
  USER_PROFILE_LAST_NAME: "Prezime",
  USER_PROFILE_FIRST_NAME_REQUIRED: "Ime ne može biti prazno polje!",
  USER_PROFILE_LAST_NAME_REQUIRED: "Prezime ne može biti prazno polje!",
  USER_PROFILE_USER_DATA_CHANGE_SUCCESS: "Uspešno promenjeni lični podaci!",
  USER_PROFILE_EMAIL: "Imejl adresa",
  USER_PROFILE_GENDER: "Pol",
  USER_PROFILE_GENDER_NO_RESULTS: "Nema rezultata",

  // ARTICLE
  ARTICLE_TITLE: "Naslov",
  ARTICLE_CODE: "Oznaka",
  ARTICLE_DISPLAY_ORDER: "Prikazni redosled",
  ARTICLE_INSERT_TIMESTAMP: "Vreme kreiranja",
  ARTICLE_TOOLTIP_CREATE: "Kreiraj članak",
  ARTICLE_TOOLTIP_UPDATE: "Izmena članka",
  ARTICLE_TOOLTIP_DELETE: "Brisanje članka",
  ARTICLE_TOOLTIP_READ: "Pregled članka",
  ARTICLE_CREATE: "Kreiranje članka",
  ARTICLE_DELETE: "Brisanje članka: ",
  ARTICLE_UPDATE: "Izmena članka: ",
  ARTICLE_READ: "Detalji članka: ",
  ARTICLE_ARTICLE_CATEGORY_NOT_EMPTY: "Kategorija članka ne može biti prazno polje!",
  ARTICLE_ARTICLE_TYPE_NOT_EMPTY: "Tip članka ne može biti prazno polje!",
  ARTICLE_NAME_NOT_EMPTY: "Naslov ne može biti prazno polje!",
  ARTICLE_CODE_NOT_EMTPY: "Oznaka ne može biti prazno polje!",
  ARTICLE_CREATE_SUCCESSFUL: "Uspešno kreiran članak: ",
  ARTICLE_DELETE_SUCCESSFUL: "Uspešno obrisan članak: ",
  ARTICLE_UPDATE_SUCCESSFUL: "Uspešno izmenjen članak: ",
  ARTICLE_TYPE: "Tip članka",
  ARTICLE_CATEGORY: "Kategorija članka",
  ARTICLE_CATEGORY_AREA: "Kategorija članka",
  ARTICLE_SUMMARY_IMAGE: "Fotografija sažetka",
  ARTICLE_HEADER_IMAGE: "Fotografija zaglavlja",
  ARTICLE_ADD_IMAGE: "+",
  ARTICLE_CATEGORY_NO_RESULT: "Nema rezultata",
  ARTICLE_URL: "Url",
  ARTICLE_URL_NOT_EMPTY: "Url za video članke ne može biti prazno polje!",
  ARTICLE_URL_MUST_BE_EMBED: "Obavezno je koristiti embed link za url videa!",

  // ARTICLE CATEGORY
  ARTICLE_CATEGORY_NAME: "Naziv",
  ARTICLE_CATEGORY_CODE: "Oznaka",
  ARTICLE_CATEGORY_DISPLAY_ORDER: "Prikazni redosled",
  ARTICLE_CATEGORY_INSERT_TIMESTAMP: "Vreme kreiranja",
  ARTICLE_CATEGORY_TOOLTIP_CREATE: "Kreiraj kategoriju",
  ARTICLE_CATEGORY_TOOLTIP_UPDATE: "Izmena kategorije",
  ARTICLE_CATEGORY_TOOLTIP_DELETE: "Brisanje kategorije",
  ARTICLE_CATEGORY_TOOLTIP_READ: "Pregled kategorije",
  ARTICLE_CATEGORY_CREATE: "Kreiranje kategorije",
  ARTICLE_CATEGORY_DELETE: "Brisanje kategorije: ",
  ARTICLE_CATEGORY_DELETE_CONFIRMATION: "Da li ste sigurni da želite da obrišete kategoriju: ",
  ARTICLE_CATEGORY_DELETE_CONFIRMATION_BUTTON_LABEL: "Nastavi",
  ARTICLE_CATEGORY_DELETE_TEXT: "Članci koji ovoj kategoriji pripadaju biće obrisani u slučaju brisanja ove kategorije:",
  ARTICLE_CATEGORY_UPDATE: "Izmena kategorije: ",
  ARTICLE_CATEGORY_READ: "Detalji kategorije: ",
  ARTICLE_CATEGORY_NAME_NOT_EMPTY: "Naziv ne može biti prazno polje!",
  ARTICLE_CATEGORY_CODE_NOT_EMTPY: "Oznaka ne može biti prazno polje!",
  ARTICLE_CATEGORY_DISPLAY_ORDER_NOT_EMPTY: "Prikazani redosled ne može biti prazno polje!",
  ARTICLE_CATEGORY_IS_MEDICAL_ARTICLE_NOT_EMPTY: "Izbor stranice ne može biti prazno polje!",
  ARTICLE_CATEGORY_VISIBILITY_NOT_EMPTY: "Morate izabrati makar jedan nivo vidljivosti!",
  ARTICLE_CATEGORY_CREATE_SUCCESSFUL: "Uspešno kreirana kategorija: ",
  ARTICLE_CATEGORY_DELETE_SUCCESSFUL: "Uspešno obrisana kategorija: ",
  ARTICLE_CATEGORY_UPDATE_SUCCESSFUL: "Uspešno izmenjena kategorija: ",
  ARTICLE_CATEGORY_VISIBILITY: "Nivo vidljivosti",
  ARTICLE_CATEGORY_CONTENT_PAGE: "Izbor stranice",

  // USER ADMINISTRATION
  USER_ADMINISTRATION_PATIENT_CODE: "Šifre za pacijente",
  USER_ADMINISTRATION_DOCTOR_CODE: "Šifre za lekare",
  USER_ADMINISTRATION_GENERATE_NEW: "Generiši novu",
  USER_ADMINISTRATION_DELETE_CODE_TOOLTIP: "Obriši šifru",
  USER_ADMINISTRATION_DELETE_CODE_SUCCESS: "Uspešno ste obrisali šifru",
  USER_ADMINISTRATION_CREATE_CODE_SUCCESS: "Uspešno ste kreirali šifru",
  USER_ADMINISTRATION_EMPTY_CODE_LIST_MESSAGE: "Nema podataka",

  // STATISTIC
  STATISTIC_FILTER_START_DATE: "Prikaži od:",
  STATISTIC_FILTER_END_DATE: "Prikaži do:",
  STATISTIC_FILTER_SEARCH: "Prikaži",
  STATISTIC_FILTER_START_DATE_NOT_EMPTY: "Prikaži od ne sme biti prazno!",
  STATISTIC_FILTER_DATE_RANGE_ERROR: "Prikaži od ne sme biti nakon Prikaži do!",
  STATISTIC_ARTICAL_REPORT: "Pročitani članci",
  STATISTIC_ARTICAL_ARTICAL_CATEGORY: "Kategorija članka",
  STATISTIC_ARTICAL_ARTICAL: "Naziv članka",
  STATISTIC_ARTICAL_TOTAL: "Broj klikova",
  STATISTIC_ARTICAL_TIME: "Ukupno vreme zadržavanja",
  STATISTIC_ARTICAL_AVG_TIME: "Prosečno vreme zadržavanja",

  APP_TOPBAR_PERSONAL_DATA: "Lični podaci",
  APP_TOPBAR_SERVICE_DATA: "Servisni podaci",

  INFO_PAGE_LABEL: "Info stranica",
  INFO_PAGE_KEY: "info",
  MEDICAL_PAGE_LABEL: "Medicinski sadržaj",
  MEDICAL_PAGE_KEY: "medical",

  ERROR_PAGE_404: "404",
  ERROR_PAGE_PAGE_NOT_FOUND: "Stranica nije pronađena.",
};

export default Labels;
