import axios, { AxiosResponse } from "axios";
import Endpoint from "../../infrastructure/Endpoint";
import UserUpdateDto from "../../model/user/UserUpdateDto";
import { AppContext } from "../../Store";
import { useContext } from "react";
import { axiosConfig } from "../../infrastructure/Utils";
import UserPasswordChangeDto from "../../model/user/UserPasswordChangeDto";

interface UserControllerType {
  axiosUpdateUser: (userId: number, userUpdate: UserUpdateDto) => Promise<AxiosResponse<any>>;
  axiosChangePassword: (userId: number, userPasswordChangeDto: UserPasswordChangeDto) => Promise<AxiosResponse<any>>;
}

export function UserController(): UserControllerType {
  const { authData } = useContext(AppContext);

  const axiosUpdateUser = (userId: number, userUpdate: UserUpdateDto) => {
    return axios.put(`${Endpoint.USER_LIST}/${userId}`, userUpdate, axiosConfig(authData?.token));
  };

  const axiosChangePassword = (userId: number, userPasswordChangeDto: UserPasswordChangeDto) => {
    return axios.post(`${Endpoint.USER_LIST}/${userId}/change-password`, userPasswordChangeDto, axiosConfig(authData?.token));
  };

  return {
    axiosUpdateUser,
    axiosChangePassword,
  };
}

export type { UserControllerType };
