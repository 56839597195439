import { Messages, MessagesSeverityType } from "primereact/messages";
import React, { Dispatch, MutableRefObject, useCallback, useContext, useEffect, useReducer, useRef } from "react";
import useEnumController from "../../controllers/useEnumController";
import EntityOperation from "../../infrastructure/EnumEntityOperation";
import actions from "../../infrastructure/system/hooks/crudReducer/actions";
import initialState from "../../infrastructure/system/hooks/crudReducer/initialState";
import reducer from "../../infrastructure/system/hooks/crudReducer/reducer";
import { handleAxiosCallError } from "../../infrastructure/Utils";
import ArticleCategoryReadSmallDto from "../../model/article/category/ArticleCategoryReadSmallDto";
import { AppContext } from "../../Store";
import CategoryListView from "./CategoryListView";

interface CategoryContextProps {
  state: any;
  dispatch: Dispatch<{ type: string; fieldName?: string; value?: any }>;
  openDialog: (entityOperation: EntityOperation, row?: ArticleCategoryReadSmallDto) => () => void;
  setReloadNeeded: (value: boolean, message?: string) => void;
  messages: MutableRefObject<Messages | null>;
  showDialogMessage: (severity: MessagesSeverityType, summary: string, detail?: string) => void;
}

export const CategoryContext = React.createContext({} as CategoryContextProps);

export default function CategoryListContainer() {
  const { showMessage } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getRoleList } = useEnumController();

  useEffect(() => {
    getRoleList()
      .then((res) => {
        dispatch({ type: actions.SET_ROLE_LIST, value: res.data });
      })
      .catch((error) => handleAxiosCallError(showMessage, error));
  }, [getRoleList, showMessage]);

  const openDialog = (entityOperation: EntityOperation, row?: ArticleCategoryReadSmallDto) => () => dispatch({ type: actions.OPEN_DIALOG, value: { entityOperation, object: row } });

  const setReloadNeeded = useCallback((value: boolean, message?: string) => dispatch({ type: actions.SET_RELOAD_NEEDED, value: { reload_needed: value, message } }), []);

  const messages = useRef<Messages>(null);
  const showDialogMessage = useCallback((severity: MessagesSeverityType, summary: string, detail?: string) => {
    try {
      messages?.current?.show({
        severity: severity,
        summary: summary,
        closable: true,
        detail: detail,
        sticky: true,
      });
    } catch {}
  }, []);

  return (
    <CategoryContext.Provider
      value={{
        state,
        dispatch,
        openDialog,
        setReloadNeeded,
        messages,
        showDialogMessage,
      }}
    >
      <CategoryListView />
    </CategoryContext.Provider>
  );
}
