import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Password } from "primereact/password";
import React from "react";
import Labels from "../../infrastructure/Labels_sr_Latn_RS";
import { NavLink } from "react-router-dom";
import LoginContainer, { LoginContainerType } from "./LoginContainer";
import { isEmptyString } from "../../infrastructure/Utils";

export default function LoginView() {
  const { email, setEmail, password, setPassword, credentialNotValidMessage, postLogin, redirectToResetPasswordView }: LoginContainerType = LoginContainer();

  return (
    <div className="layout-login">
      <div className="col-12 p-lg-6">
        <div className="col-12 panel-registration d-flex justify-content-center">
          <div className="p-grid p-dir-row p-nogutter p-justify-center p-align-center">
            <Panel header={Labels.TITLE_LOGIN}>
              <form onSubmit={postLogin}>
                <div className="col-12">
                  <div>
                    <div className="col-12 pb-3">
                      <InputText
                        placeholder={Labels.PLACEHOLDER_EMAIL_REQUIRED}
                        value={email}
                        onChange={(e: any) => {
                          setEmail(e.target.value);
                        }}
                        autoComplete="email"
                      />
                    </div>
                    <div className="col-12 login-input">
                      <Password
                        placeholder={Labels.PLACEHOLDER_PASSWORD}
                        feedback={false}
                        value={password}
                        onChange={(e: any) => {
                          setPassword(e.target.value);
                        }}
                        autoComplete="current-password"
                      />
                      {credentialNotValidMessage && (
                        <p id="credentialsValidation" className="p-error block credentials-validation">
                          {credentialNotValidMessage}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-12 login-button">
                    <NavLink to="/">
                      <Button label={Labels.BUTTON_LOGIN} className="col-12" onClick={postLogin} disabled={isEmptyString(email) || isEmptyString(password)} />
                    </NavLink>
                  </div>

                  <div className="redirectButton">
                    <Button label={Labels.FORGOTTEN_PASSWORD} className="text-muted" onClick={redirectToResetPasswordView} />
                  </div>
                </div>
              </form>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  );
}
