import React, { useContext, useState } from "react";
import { UserController, UserControllerType } from "../../../../../../controllers/user/UserController";
import MessageType from "../../../../../../infrastructure/MessageType";
import { handleAxiosCallError, validateStringEmpty } from "../../../../../../infrastructure/Utils";
import { AppContext } from "../../../../../../Store";
import ServiceDataDialog from "./ServiceDataDialog";
import Labels from "../../../../../../infrastructure/Labels_sr_Latn_RS";
import UserPasswordChangeDto from "../../../../../../model/user/UserPasswordChangeDto";
import useShowDialogMessage from "../../../../../../infrastructure/system/hooks/useShowDialogMessage";

interface ServiceDataDialogContainerProps {
  setShowServiceDataDialog: React.Dispatch<React.SetStateAction<boolean>>;
  showServiceDataDialog: boolean;
}

export default function ServiceDataDialogContainer({ setShowServiceDataDialog, showServiceDataDialog }: ServiceDataDialogContainerProps) {
  const { authData, showMessage, setShowBlockUI } = useContext(AppContext);
  const [passwordChange, setPasswordChange] = useState<UserPasswordChangeDto | undefined>();
  const { axiosChangePassword }: UserControllerType = UserController();
  const { messages, showDialogMessage } = useShowDialogMessage();

  const validateInput = () => {
    if (validateStringEmpty(passwordChange?.currentPassword)) {
      showDialogMessage(MessageType.ERROR, Labels.USER_PROFILE_PASSWORD_CHANGE_ERROR, Labels.USER_PROFILE_MESSAGES_OLD_PASSWORD);
      return false;
    }

    if (validateStringEmpty(passwordChange?.newPassword)) {
      showDialogMessage(MessageType.ERROR, Labels.USER_PROFILE_PASSWORD_CHANGE_ERROR, Labels.USER_PROFILE_MESSAGES_NEW_PASSWORD);
      return false;
    }

    if (validateStringEmpty(passwordChange?.confirmPassword)) {
      showDialogMessage(MessageType.ERROR, Labels.USER_PROFILE_PASSWORD_CHANGE_ERROR, Labels.USER_PROFILE_MESSAGES_PASSWORD_CONFIRMATION);
      return false;
    }

    if (passwordChange?.newPassword !== passwordChange?.confirmPassword) {
      showDialogMessage(MessageType.ERROR, Labels.USER_PROFILE_PASSWORD_CHANGE_ERROR, Labels.USER_PROFILE_MESSAGES_PASSWORDS_NOT_SAME);
      return false;
    }

    return true;
  };

  const closeDialog = () => {
    setShowServiceDataDialog(false);
  };

  const changePassword = () => {
    if (validateInput()) {
      setShowBlockUI(true);
      axiosChangePassword(authData.currentUser.id, passwordChange!)
        .then(() => {
          showMessage(MessageType.SUCCESS, Labels.USER_PROFILE_CHANGE_PASSWORD_SUCCESS);
          closeDialog();
        })
        .catch((error) => {
          handleAxiosCallError(showDialogMessage, error);
        })
        .finally(() => {
          setShowBlockUI(false);
        });
    }
  };

  return (
    <ServiceDataDialog
      closeDialog={closeDialog}
      showServiceDataDialog={showServiceDataDialog}
      passwordChange={passwordChange}
      setPasswordChange={setPasswordChange}
      changePassword={changePassword}
      messages={messages}
    />
  );
}
