import axios, { AxiosResponse } from "axios";
import Endpoint from "../../infrastructure/Endpoint";
import { axiosConfig } from "../../infrastructure/Utils";
import { AppContext } from "../../Store";
import { useCallback, useContext } from "react";
import ArticleCreateDto from "../../model/article/ArticleCreateDto";
import ArticleUpdateDto from "../../model/article/ArticleUpdateDto";

interface useArticleControllerType {
  getArticlesList: () => Promise<AxiosResponse<any>>;
  getArticleListByCategory: (articleCategoryId: number) => Promise<AxiosResponse<any, any>>;
  getArticle: (articleId: number) => Promise<AxiosResponse<any>>;
  createArticle: (article: ArticleCreateDto) => Promise<AxiosResponse<any>>;
  updateArticle: (article: ArticleUpdateDto) => Promise<AxiosResponse<any>>;
  deleteArticle: (articleId: number) => Promise<AxiosResponse<any>>;
}

export default function useArticleController(): useArticleControllerType {
  const { authData } = useContext(AppContext);

  const getArticlesList = useCallback(() => {
    return axios.get(`${Endpoint.ARTICLE_LIST}`, axiosConfig(authData.token, { size: 50000, page: 0 }));
  }, [authData.token]);

  const getArticleListByCategory = useCallback(
    (articleCategoryId: number) => {
      return axios.get(`${Endpoint.ARTICLE_LIST_BY_CATEGORY}/${articleCategoryId}`, axiosConfig(authData.token));
    },
    [authData.token]
  );

  const getArticle = useCallback(
    (articleId: number) => {
      return axios.get(`${Endpoint.ARTICLE_LIST_ADMIN}/${articleId}`, axiosConfig(authData.token));
    },
    [authData.token]
  );

  const createArticle = useCallback(
    (article: ArticleCreateDto) => {
      return axios.post(Endpoint.ARTICLE_LIST, article ? article : {}, axiosConfig(authData.token));
    },
    [authData.token]
  );

  const updateArticle = useCallback(
    (article: ArticleUpdateDto) => {
      return axios.put(`${Endpoint.ARTICLE_LIST}/${article?.id}`, article, axiosConfig(authData.token));
    },
    [authData.token]
  );

  const deleteArticle = useCallback(
    (articleId: number) => {
      return axios.delete(`${Endpoint.ARTICLE_LIST}/${articleId}`, axiosConfig(authData.token));
    },
    [authData.token]
  );

  return {
    getArticlesList,
    getArticleListByCategory,
    getArticle,
    createArticle,
    updateArticle,
    deleteArticle,
  };
}
