import axios, { AxiosResponse } from "axios";
import Endpoint from "../../infrastructure/Endpoint";
import { axiosConfig } from "../../infrastructure/Utils";
import { useContext } from "react";
import { AppContext } from "../../Store";

interface StatisticControllerType {
  axiosGetStatisticReport: (filter: any) => Promise<AxiosResponse<any>>;
  axiosGetArticleStatisticReport: (filter: any) => Promise<AxiosResponse<any>>;
}

export function StatisticController(): StatisticControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetStatisticReport = (filter: any) => {
    return axios.get(Endpoint.STATISTIC_REPORT, axiosConfig(authData.token, filter));
  };

  const axiosGetArticleStatisticReport = (filter: any) => {
    return axios.get(Endpoint.STATISTIC_ARTICLE_REPORT, axiosConfig(authData.token, filter));
  };

  return {
    axiosGetStatisticReport,
    axiosGetArticleStatisticReport,
  };
}

export type { StatisticControllerType };
