import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React, { MutableRefObject } from "react";
import { MenuItem } from "../../../../model/MenuModel";

interface MenuResizableViewProps {
    menu: MutableRefObject<Menu | null>;
    menuModel: MenuItem[];
}

export default function MenuResizableView({ menu, menuModel }: MenuResizableViewProps) {

    return (
        <div className="max-width-800">
            <div>
                <Menu ref={menu} model={menuModel} popup={true} />
                <Button
                    className="bars-icon gray-button rounded-button"
                    icon="pi pi-bars"
                    onClick={(event) => {
                        menu?.current?.toggle(event);
                    }}
                />
            </div>
        </div>
    )
}