import axios, { AxiosResponse } from "axios";
import { useCallback, useContext } from "react"
import Endpoint from "../../infrastructure/Endpoint";
import { axiosConfig } from "../../infrastructure/Utils";
import ArticleCategoryCreateDto from "../../model/article/category/ArticleCategoryCreateDto";
import ArticleCategoryUpdateDto from "../../model/article/category/ArticleCategoryUpdateDto";
import { AppContext } from "../../Store"

interface useArticleCategoryControllerType {
    getArticleCategoryList: () => Promise<AxiosResponse<any>>;
    createArticleCategory: (articleCategoryCreateDto: ArticleCategoryCreateDto) => Promise<AxiosResponse<any>>;
    deleteArticleCategory: (id: number) => Promise<AxiosResponse<any>>;
    getArticleCategory: (id: number) => Promise<AxiosResponse<any>>;
    updateArticleCategory: (articleCategoryUpdateDto: ArticleCategoryUpdateDto) => Promise<AxiosResponse<any>>;
}

export default function useArticleCategoryController(): useArticleCategoryControllerType {
    const { authData } = useContext(AppContext)

    const getArticleCategoryList = useCallback(() => {
        return axios.get(Endpoint.ARTICLE_CATEGORY_LIST, axiosConfig(authData.token));
    }, [authData.token]);

    const getArticleCategory = useCallback((id: number) => {
        return axios.get(`${Endpoint.ARTICLE_CATEGORY_LIST}/${id}`, axiosConfig(authData.token));
    }, [authData.token])

    const createArticleCategory = useCallback((articleCategoryCreateDto: ArticleCategoryCreateDto) => {
        return axios.post(Endpoint.ARTICLE_CATEGORY_LIST, articleCategoryCreateDto, axiosConfig(authData.token));
    }, [authData.token]);

    const updateArticleCategory = useCallback((articleCategoryUpdateDto: ArticleCategoryUpdateDto) => {
        return axios.put(`${Endpoint.ARTICLE_CATEGORY_LIST}/${articleCategoryUpdateDto.id}`, articleCategoryUpdateDto, axiosConfig(authData.token));
    }, [authData.token]);

    const deleteArticleCategory = useCallback((id: number) => {
        return axios.delete(`${Endpoint.ARTICLE_CATEGORY_LIST}/${id}`, axiosConfig(authData.token));
    }, [authData.token]);

    return {
        getArticleCategoryList,
        createArticleCategory,
        updateArticleCategory,
        deleteArticleCategory,
        getArticleCategory
    }
}