import axios, { AxiosResponse } from "axios";
import Endpoint from "../../infrastructure/Endpoint";
import { AppContext } from "../../Store";
import { useCallback, useContext } from "react";
import { axiosConfig } from "../../infrastructure/Utils";

interface RoleCodeControllerType {
  axiosGetRoleCodeByRole: (role: string) => Promise<AxiosResponse<any>>;
  axiosDeleteRoleCode: (id: string) => Promise<AxiosResponse<any>>;
  axiosCreateRoleCode: (role: string) => Promise<AxiosResponse<any>>;
}

export function RoleCodeController(): RoleCodeControllerType {
  const { authData } = useContext(AppContext);

  const axiosGetRoleCodeByRole = useCallback(
    (role: string) => {
      return axios.get(`${Endpoint.ROLE_CODE}/${role}`, axiosConfig(authData.token));
    },
    [authData.token]
  );

  const axiosDeleteRoleCode = (id: string) => {
    return axios.delete(`${Endpoint.ROLE_CODE}/${id}`, axiosConfig(authData.token));
  };

  const axiosCreateRoleCode = (role: string) => {
    return axios.post(`${Endpoint.ROLE_CODE}`, { role }, axiosConfig(authData.token));
  };

  return {
    axiosGetRoleCodeByRole,
    axiosDeleteRoleCode,
    axiosCreateRoleCode,
  };
}

export type { RoleCodeControllerType };
