export default class UserReadDto {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  gender: string;
  registration_complete: boolean;
  firebase_device_id: string;
  activation_code: string;

  constructor(id: number, email: string, firstname: string, lastname: string, gender: string, registration_complete: boolean, activation_code: string, firebase_device_id: string) {
    this.id = id;
    this.email = email;
    this.firstname = firstname;
    this.lastname = lastname;
    this.gender = gender;
    this.registration_complete = registration_complete;
    this.activation_code = activation_code;
    this.firebase_device_id = firebase_device_id;
  }
}
