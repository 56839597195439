import { InputText } from "primereact/inputtext";
import { InputNumber, InputNumberValueChangeParams } from "primereact/inputnumber";
import React, { ChangeEvent, useContext } from "react";
import Labels from "../../../../infrastructure/Labels_sr_Latn_RS";
import { CategoryContext } from "../../CategoryListContainer";
import objectFieldNames from "../../../../infrastructure/system/hooks/crudReducer/objectFieldNames";
import EntityOperation from "../../../../infrastructure/EnumEntityOperation";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { EnumDto } from "../../../../model/EnumDto";
import { RadioButton, RadioButtonChangeParams } from "primereact/radiobutton";
import { PageType } from "../../../../model/PageType";

interface CrudCategoryViewProps {
  onChangeTextInput: (fieldName: string) => (event: ChangeEvent<HTMLInputElement> | InputNumberValueChangeParams) => void;
  onChangeChecbox: (event: CheckboxChangeParams) => void;
  onChangeRadioButton: (event: RadioButtonChangeParams) => void;
  pageChoices: PageType[];
}

export default function CrudCategoryView({ onChangeTextInput, onChangeChecbox, onChangeRadioButton, pageChoices }: CrudCategoryViewProps) {
  const {
    state: { object, entity_operation, role_list },
  } = useContext(CategoryContext);

  const disabled = [EntityOperation.READ, EntityOperation.DELETE].includes(entity_operation);

  const ChecboxCustom = (role: EnumDto) => {
    return (
      <div key={role.code} className="mb-2">
        <Checkbox disabled={disabled} inputId={role.code} value={role.code} checked={!!object?.articleCategoryRole?.find((aR: { role: string }) => aR.role === role.code)} onChange={onChangeChecbox} />
        <label htmlFor={role.code} className="cursor-pointer p-checkbox-label ml-4">
          {role.name}
        </label>
      </div>
    );
  };

  const RadioButtonCustom = (page: PageType) => {
    return (
      <div key={page.key} className="field-radiobutton">
        <RadioButton disabled={disabled} inputId={page.key} name="page-choice" checked={object?.isMedicalArticle === page.value} value={page.value} onChange={onChangeRadioButton} />
        <label htmlFor={page.key}>{page.name}</label>
      </div>
    );
  };

  return (
    <div className="py-5 mt-4">
      <div className="flex col-12">
        <div className="col-4">{Labels.ARTICLE_CATEGORY_NAME + Labels.SPECIAL_CHAR_REQUIRED}</div>
        <div className="col-6">
          <InputText disabled={disabled} value={object?.name ?? Labels.EMPTY_STRING} onChange={onChangeTextInput(objectFieldNames.NAME)} />
        </div>
      </div>
      <div className="flex col-12">
        <div className="col-4">{Labels.ARTICLE_CATEGORY_DISPLAY_ORDER + Labels.SPECIAL_CHAR_REQUIRED}</div>
        <div className="col-2 w-10-rem">
          <InputNumber
            disabled={disabled}
            value={object?.displayOrder}
            onValueChange={onChangeTextInput(objectFieldNames.DISPLAY_ORDER)}
            min={1}
            step={1}
            showButtons
            buttonLayout="horizontal"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            decrementButtonClassName="p-button-text"
            incrementButtonClassName="p-button-text"
            inputClassName="px-0"
          />
        </div>
      </div>

      <div className="flex col-12">
        <div className="col-4">{Labels.ARTICLE_CATEGORY_CONTENT_PAGE + Labels.SPECIAL_CHAR_REQUIRED}</div>
        <div className="col-6 flex flex-column">{pageChoices.map(RadioButtonCustom)}</div>
      </div>

      {object?.isMedicalArticle === false && (
        <div className="flex col-12">
          <div className="col-4">{Labels.ARTICLE_CATEGORY_VISIBILITY + Labels.SPECIAL_CHAR_REQUIRED}</div>
          <div className="col-6 flex flex-column">{role_list.map(ChecboxCustom)}</div>
        </div>
      )}
    </div>
  );
}
